import { t } from '^/i18n';

export const EventRecordStatus = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED',
};

export const EventRecordStatusLabels = {
  [EventRecordStatus.OPEN]: t('common.button.open', 'Open'),
  [EventRecordStatus.IN_PROGRESS]: t(
    'common.label.inProgress.capitalCase',
    'In Progress'
  ),
  [EventRecordStatus.CLOSED]: t('common.label.closed', 'Closed'),
};

export const EVENT_TYPE_OPTIONS = [
  {
    key: 'PROBLEM',
    value: t('digitalTools.records.problem', 'Problem'),
  },
  {
    key: 'SAFETY_INCIDENT',
    value: t('digitalTools.records.safetyIncident', 'Safety Incident'),
  },
  {
    key: 'SERIOUS_INCIDENT',
    value: t('digitalTools.records.seriousIncident', 'Serious Incident'),
  },
  {
    key: 'SAFETY_ALERT',
    value: t('digitalTools.records.safetyAlert', 'Safety Alert'),
  },
  {
    key: 'NEVER_EVENT',
    value: t('digitalTools.records.neverEvent', 'Never Event'),
  },
  {
    key: 'HAZARD_OBSERVATION',
    value: t('digitalTools.records.hazardObservation', 'Hazard Observation'),
  },
  {
    key: 'NEGATIVE_REVIEW',
    value: t('digitalTools.records.negativeReview', 'Negative Review'),
  },
  {
    key: 'SIGNIFICANT',
    value: t('digitalTools.records.significant', 'Significant'),
  },
  {
    key: 'OTHER',
    value: t('activities.activityTagText.Other', 'Other'),
  },
  {
    key: 'COMPLIMENT',
    value: t('digitalTools.records.compliment', 'Compliment'),
  },
  {
    key: 'COMPLAINT',
    value: t('digitalTools.records.complaint', 'Complaint'),
  },
];
