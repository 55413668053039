import classNames from 'classnames';
import React from 'react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';

import { formatBackendDate, FORMAT_DATE } from '^/utils';
import PureComponent from '^/components/common/PureComponent';


export const DatePickerInput = ({ value, onClick, placeholder, onClear, className, isDisabled }) => {
  return (
    <span className="date-picker">
      <span
        className={classNames(className, isDisabled && 'disabled not-allowed')}
        onClick={!isDisabled && onClick}
      >
        {value || placeholder}
      </span>
      { value && onClear &&
        <span
          className={classNames('clear-picker', isDisabled && 'disabled not-allowed')}
          onClick={!isDisabled && onClear}
        >
          Clear
        </span>
      }
    </span>
  );
};

export class DatePicker extends PureComponent {
  constructor(props) {
    super(props);
    this.getInitialValue = this.getInitialValue.bind(this);
  }

  getInitialValue() {
    const { selectedValue } = this.props;
    return selectedValue && moment(selectedValue).isValid() 
      ? moment(selectedValue) : null;
  }

  render() {
    const {
      className, placeholderText, isClearable, minDate, maxDate, isDisabled,
      onChange, clearValue
    } = this.props;
    return (
      <ReactDatePicker
        fixedHeight
        showYearDropdown
        dateFormat={FORMAT_DATE}
        selected={this.getInitialValue()}
        onChange={date => onChange(formatBackendDate(date))}
        minDate={(minDate || null) && moment(minDate)}
        maxDate={(maxDate || null) && moment(maxDate)}
        placeholderText={placeholderText}
        className={className}
        customInput={
          <DatePickerInput
            onClear={isClearable 
              ? (() => onChange(clearValue)) 
              : null}
            isDisabled={isDisabled}
          />
        }
      />
    );
  }
}

DatePicker.propTypes = {
  selectedValue: React.PropTypes.string,
  className: React.PropTypes.string,
  placeholderText: React.PropTypes.string,
  isClearable: React.PropTypes.bool,
  minDate: React.PropTypes.string,
  maxDate: React.PropTypes.string,
  onChange: React.PropTypes.func.isRequired,
  clearValue: React.PropTypes.string,
};

export default DatePicker;
