import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { t } from '^/i18n';
import { closeModal } from '^/actions/modals';
import LiveButton, { DefaultErrorMessage } from '^/components/app/forms/LiveButton';
import { FormGroupInput, FormGroupSelect } from '^/components/app/groups/group-documents/GroupTemplateDocumentForm';
import {
  GROUP_CREATED_TEMPLATE_DOCUMENTS,
  TEMPLATE_CATEGORIES,
  TEMPLATE_FOLDERS,
} from '^/consts/collectionKeys';
import { hasFailed, hasSucceeded, isPending } from '^/consts/responseStates';
import { KINDS_DISPLAY, DOCUMENT_PRIORITY_OPTIONS, KINDS } from '^/models/documents';

class GroupTemplateDocumentEditForm extends React.Component {
  componentDidMount() {
    const { fields: { kind, enable_doc_reads } } = this.props;
    const isOverviewOrPolicy = kind.initialValue === KINDS.OVERVIEW || kind.initialValue === KINDS.POLICY;

    if (isOverviewOrPolicy) {
      enable_doc_reads.onChange(true);
      enable_doc_reads.disabled = true;
    }

    kind.disabled = true;
  }

  render() {
    const {
      fields: { name, code, folder, template_category, enable_doc_reads, kind, priority },
      handleSubmit,
      folders,
      categories,
      response,
      isUpdating,
      hasUpdated,
      updateFailed,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormGroupInput
          field={name}
          fieldName="name"
          label={t('groups.name', 'Name')}
          type="text"
          response={response}
        />
        <FormGroupInput
          field={code}
          fieldName="code"
          label={t('groups.code', 'Code')}
          type="text"
          response={response}
        />
        <FormGroupSelect
          field={folder}
          fieldName="folder"
          label={t('groups.folder', 'Folder')}
          options={folders}
          response={response}
        />
        <FormGroupSelect
          field={kind}
          fieldName="kind"
          label={t('groups.kind', 'Kind')}
          options={KINDS_DISPLAY}
          response={response}
        />
        <FormGroupSelect
          field={template_category}
          fieldName="template_category"
          label={t('groups.documentCategory', 'Document category')}
          options={categories}
          response={response}
        />
        <FormGroupInput
          field={enable_doc_reads}
          fieldName="enable_doc_reads"
          label={t('groups.enableSendToTeam', 'Enable \'Send to team\'')}
          type="checkbox"
          response={response}
        />
        <FormGroupSelect
          field={priority}
          fieldName="priority"
          label={t('groups.priority', 'Priority')}
          options={DOCUMENT_PRIORITY_OPTIONS}
        />
        <div className="col-1">
          <LiveButton
            className="btn btn-primary pull-left"
            response={response}
            disabled={isUpdating || hasUpdated}
            hideDefaultErrorMessage
          >
            {t('common.button.save', 'Save')}
          </LiveButton>
          <button className="btn btn-default ml-1" onClick={closeModal}>
            {t('common.button.cancel', 'Cancel')}
          </button>
          {updateFailed && <DefaultErrorMessage /> }
        </div>
      </form>
    );
  }
}

export { GroupTemplateDocumentEditForm as UnconnectedGroupTemplateDocumentEditForm };

const FormifiedGroupTemplateDocumentEditForm = reduxForm({
  form: 'GroupTemplateDocumentEditForm',
  fields: [
    'name',
    'code',
    'folder',
    'kind',
    'template_category',
    'priority',
    'enable_doc_reads',
  ],
})(GroupTemplateDocumentEditForm);

const mapStateToProps = (state) => {
  const response = state.responses.getIn(['updateItem', GROUP_CREATED_TEMPLATE_DOCUMENTS]);
  return {
    categories: state.collections.getIn([TEMPLATE_CATEGORIES, 'items'], List()),
    folders: state.collections.getIn([TEMPLATE_FOLDERS, 'items'], List()),
    isUpdating: isPending(response),
    hasUpdated: hasSucceeded(response),
    updateFailed: hasFailed(response),
    response,
  }
};

export default connect(mapStateToProps, {
  closeModal
})(FormifiedGroupTemplateDocumentEditForm);
