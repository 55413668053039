import React from 'react';

import Icon from '^/components/app/content/Icon';
import DatePicker from '^/components/app/widgets/DatePicker';

export const DateRangeFilter = ({
  fromFilterKey,
  toFilterKey,
  label,
  selectedFromValue,
  selectedToValue,
  isDisabled,  
  onChange,
  minDate,
  maxDate,
  isClearable,
}) => {
  return (
    <div>
      <label className="display-block light-text">{label}</label>
      <span className="date-wrapper print-hide">
        <DatePicker
          selectedValue={selectedFromValue}
          minDate={minDate}
          maxDate={selectedToValue}
          isDisabled={isDisabled}
          onChange={val => onChange(fromFilterKey, val)}
          placeholderText="All"
          isClearable={isClearable}
        />
        <Icon type="arrow-right" className="link-icon ml-1-2" />
        <DatePicker
          className="ml-1-2"
          selectedValue={selectedToValue}
          minDate={selectedFromValue}
          maxDate={maxDate}
          isDisabled={isDisabled}
          onChange={val => onChange(toFilterKey, val)}
          placeholderText="All"
          isClearable={isClearable}
        />
      </span>
    </div>
  );
};

DateRangeFilter.propTypes = {
  fromFilterKey: React.PropTypes.string.isRequired,
  toFilterKey: React.PropTypes.string.isRequired,
  label: React.PropTypes.string,
  selectedFromValue: React.PropTypes.string,
  selectedToValue: React.PropTypes.string,
  isDisabled: React.PropTypes.bool,
  onChange: React.PropTypes.func.isRequired,
  minDate: React.PropTypes.string,
  maxDate: React.PropTypes.string,
  isClearable: React.PropTypes.bool,
};

export default DateRangeFilter;
