import React from 'react';

import AdminContainer from '^/components/admin/content/AdminContainer';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';

import { connect } from 'react-redux';
import { isPending } from '^/consts/responseStates';
import { generateReport } from '^/actions/actionSequences';
import { openGroupActivitiesAndTasksReportModal } from '^/actions/modals';
import { REPORT_TYPES } from '^/models/reports';

const ReportTypeButton = ({reportType, disabled, generate}) =>
  <button
    className="btn btn-default mr-1 mb-1"
    onClick={() => generate(reportType)}
    disabled={disabled}
  >
    {reportType.get('display_name')}
  </button>;

class ReportsAdmin extends PureComponent {
  render() {
    const buttonsDisabled = isPending(this.props.response);
    return (
      <div>
        <AdminContainer className="mb-2" title="Reports">
          <fieldset>
            <legend>Select a button below to generate a report</legend>
            {REPORT_TYPES.map(
              reportType =>
                <ReportTypeButton
                  key={reportType}
                  reportType={reportType}
                  disabled={buttonsDisabled}
                  generate={this.props.generate}
                />
            )}
            <button
              className="btn btn-default mr-1 mb-1"
              onClick={this.props.openGroupActivitiesAndTasksReportModal}
              disabled={buttonsDisabled}
            >
              Group Activities And Tasks
            </button>
          </fieldset>
          { buttonsDisabled && <Loading /> }
        </AdminContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('getReportURL')
  };
}

export default connect(mapStateToProps, {
  generate: generateReport,
  openGroupActivitiesAndTasksReportModal
})(ReportsAdmin);
