import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { closeModal } from '^/actions/modals';
import { discardCurrentAndAdoptNewVersionAndCloseModal } from '^/actions/actionSequences';

export const DiscardCurrentAndAdoptNewVersionModal = ({
  documentContent,
  documentId,
  documentDetailLink,
  templateDocumentVersion,
  currentPracticeId,
  currentGroupId,
  activityId,
  onConfirm,
  onClose
}) => {

  const isGroupDocument = templateDocumentVersion.get(
    'group_template_document'
  );

  return (
    <div>
      <div className="row">
        <div className="col-1">
          <p>
            {t('templateDocument.discardNewVersionConfirmationMsg', 'All of your existing document edits will be lost and replaced with the new version. This is not reversible. Are you sure you want to proceed?')}
          </p>
        </div>
      </div>
      <div className="row align-right mt-1">
        <div className="col-1">
          <button className="btn btn-default" onClick={onClose}>
            {t('common.button.cancel', 'Cancel')}
          </button>
          <button className="btn btn-warning ml-1" onClick={() => onConfirm(
              documentId,
              documentContent,
              isGroupDocument ? null: currentPracticeId,
              isGroupDocument ? currentGroupId : null,
              documentDetailLink,
              activityId
          )}>
            {t('common.confirm', 'Confirm')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {onConfirm: discardCurrentAndAdoptNewVersionAndCloseModal, onClose: closeModal}) (DiscardCurrentAndAdoptNewVersionModal);
