import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { getReportURL, resetResponse } from '^/actions/actions';
import PureComponent from '^/components/common/PureComponent';
import PollDownload from '^/components/common/PollDownload';
import { isPending, hasFailed } from '^/consts/responseStates';

const ONE_WEEK = 'ONE_WEEK';
const TWO_WEEKS = 'TWO_WEEKS';
const THREE_WEEKS = 'THREE_WEEKS';
const ONE_MONTH = 'ONE_MONTH';

const getRadioInputId = value => `time-period-${value}`;

const RadioInput = ({ value, label, onChange, isDisabled }) => (
  <div>
    <input
      id={getRadioInputId(value)}
      type="radio"
      name="time-period"
      value={value}
      onChange={onChange}
      disabled={isDisabled}
    />
    <label htmlFor={getRadioInputId(value)}>{label}</label>
  </div>
);

export class GroupActivitiesAndTasksReportModal extends PureComponent {
  constructor(props) {
    super(props);
    this.setSelectedTimePeriod = this.setSelectedTimePeriod.bind(this);
    this.createReport = this.createReport.bind(this);
    this.state = {
      selectedTimePeriod: null,
    };
  }

  setSelectedTimePeriod(event) {
    this.setState({ selectedTimePeriod: event.target.value });
  }

  createReport(event) {
    event.preventDefault();
    if (!this.props.isCreatingReport) {
      this.props.createReport(this.state.selectedTimePeriod);
    }
  }

  render() {
    const {
      exportId,
      valid,
      radioButtonsDisabled,
      resetCreateReportResponse,
      isCreatingReport,
      hasFailedCreateReport,
      createReportError,
    } = this.props;

    return (
      <div>
        <p>
          {/* NOTE - Remove '(Rodericks)' if a group select is added */}
          Generate Group Activities And Task report (Rodericks). Include
          activities and tasks due from 18 months ago, or select a starting due
          date below.
        </p>
        <RadioInput
          value={ONE_WEEK}
          label="One week ago"
          onChange={this.setSelectedTimePeriod}
          isDisabled={radioButtonsDisabled}
        />
        <RadioInput
          value={TWO_WEEKS}
          label="Two weeks ago"
          onChange={this.setSelectedTimePeriod}
          isDisabled={radioButtonsDisabled}
        />
        <RadioInput
          value={THREE_WEEKS}
          label="Three weeks ago"
          onChange={this.setSelectedTimePeriod}
          isDisabled={radioButtonsDisabled}
        />
        <RadioInput
          value={ONE_MONTH}
          label="One month ago"
          onChange={this.setSelectedTimePeriod}
          isDisabled={radioButtonsDisabled}
        />
        <div className="mt-1">
          {exportId ? (
            <PollDownload
              exportId={exportId}
              fileType="CSV"
              onRetry={resetCreateReportResponse}
            />
          ) : (
            <a
              href="#"
              className="btn btn-primary fullwidth"
              onClick={this.createReport}
              disabled={isCreatingReport}
            >
              Generate
            </a>
          )}
        </div>
        {valid && (
          <p className="mt-1">
            Please note: The download link will only be valid for the next{' '}
            {valid} minutes!
          </p>
        )}
        {hasFailedCreateReport && (
          <p className="mt-1 text-error">
            Failed to create report: {createReportError}
          </p>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const createReportResponse = state.responses.get('getReportURL');
  const responsePayload = createReportResponse.get('payload', Map());
  const isCreatingReport = isPending(createReportResponse);
  const exportId = responsePayload.get('export_id');
  return {
    isCreatingReport,
    hasFailedCreateReport: hasFailed(createReportResponse),
    createReportError: createReportResponse.get(
      'errors',
      'A report may still be running.'
    ),
    exportId,
    valid: responsePayload.get('valid'),
    radioButtonsDisabled: !!exportId || isCreatingReport,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createReport: selectedTimePeriod => {
      const queryParams = selectedTimePeriod
        ? `?period=${selectedTimePeriod}`
        : '';
      dispatch(getReportURL('group-activities-and-tasks-report', queryParams));
    },
    resetCreateReportResponse: () => dispatch(resetResponse('getReportURL')),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupActivitiesAndTasksReportModal);
