import React from 'react';
import { connect } from 'react-redux';

import { switchLocaleAndReload } from '^/actions/actionSequences';
import { globalFeatureIsEnabled } from '^/stateHelpers';


const LOCALE_OPTIONS = [
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'nl',
    label: 'Nederlands'
  }
]

export const LocaleSwitcher = ({ switchLocale, featureEnabled }) => {
  const currentLocale = window.USER_PREFERRED_LANGUAGE;

  return featureEnabled ? (
    <select
      className="nav-switcher"
      key="nav-switcher"
      defaultValue={currentLocale}
      onChange={switchLocale}
    >
      {LOCALE_OPTIONS.map(option => (
        <option key={`locale-${option.value}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  ) : (
    <span/>
  )
};

function mapStateToProps(state) {
  return {
    featureEnabled: globalFeatureIsEnabled(state, 'lang_picker')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    switchLocale: (event) => {
      const currentLocale = window.USER_PREFERRED_LANGUAGE;
      const newLocale = event.target.value;

      if (newLocale !== currentLocale) {
        dispatch(switchLocaleAndReload(newLocale));
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocaleSwitcher);
