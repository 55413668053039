import React from 'react';
import { connect } from 'react-redux';
import FilterBar from '^/components/app/filters/FilterBar';
import SelectFilter from '^/components/app/filters/SelectFilter';
import DateRangeFilter from '^/components/app/filters/DateRangeFilter';
import TextFilter from '^/components/app/filters/TextFilter';
import { READ_TRACKER_FILTER_KEYS as FILTER_KEYS } from './constants';
import { getActivePracticeUsers } from '^/stateHelpers';

export const DocumentReadFilterBar = ({ 
  filters, onFilterChanging, onFilterChanged, onClearClicked, isDisabled, 
  practiceMembers 
}) => {
  const filterComponents = [
    <TextFilter
      key="docname"
      label="Document Name"
      placeholder="Search"
      filterKey={FILTER_KEYS.DOCUMENT_NAME}
      value={filters[FILTER_KEYS.DOCUMENT_NAME]}
      onChange={onFilterChanging}
      onChangeComplete={onFilterChanged}
      isDisabled={isDisabled}
    />,
    <TextFilter
      key="doccode"
      label="Document Code"
      placeholder="Search"
      filterKey={FILTER_KEYS.DOCUMENT_CODE}
      value={filters[FILTER_KEYS.DOCUMENT_CODE]}
      onChange={onFilterChanging}
      onChangeComplete={onFilterChanged}
      isDisabled={isDisabled}
    />,
    <SelectFilter
      key="sentto"
      label="Sent To"
      filterKey={FILTER_KEYS.SENT_TO}
      onChange={onFilterChanged}
      isDisabled={isDisabled}
      selectedValue={filters[FILTER_KEYS.SENT_TO]}
      options={practiceMembers.map(member => { 
        return { 
          value: member.get("id"),
          label: member.getIn(["user", "staffdetail", "full_name"]), 
        };
      })}
    />,
    <DateRangeFilter
      key="sentdate"
      label="Sent Date"
      fromFilterKey={FILTER_KEYS.DATE_FROM}
      toFilterKey={FILTER_KEYS.DATE_TO}
      onChange={onFilterChanged}
      isDisabled={isDisabled}
      selectedFromValue={filters[FILTER_KEYS.DATE_FROM]}
      selectedToValue={filters[FILTER_KEYS.DATE_TO]}
      isClearable
    />,
  ];
  return (
    <FilterBar
      filterComponents={filterComponents}
      filterClassNames={[
        "col-3-12 mt-1-2",
        "col-2-12 mt-1-2",
        "col-2-12 mt-1-2",
        "col-4-12 mt-1-2",
      ]}
      isDisabled={isDisabled}
      onClearClicked={onClearClicked}
    />
  );
};

DocumentReadFilterBar.propTypes = {
  filters: React.PropTypes.object.isRequired,
  onFilterChanging: React.PropTypes.func.isRequired,
  onFilterChanged: React.PropTypes.func.isRequired,
  onClearClicked: React.PropTypes.func.isRequired,
  isDisabled: React.PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    practiceMembers: getActivePracticeUsers(state),
  };
};

export default connect(mapStateToProps, null)(DocumentReadFilterBar);
