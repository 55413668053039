import { fromJS } from 'immutable';
import {
  CLOSE_MODAL,
  addCloseButtonAfterPolling,
  removeCloseButtonWhilePolling,
} from '^/actions/modals';
import * as actions from '^/actions/actions';
import { pollInProgress, pollComplete, pollFailed } from '^/models/export';
import settings from '^/consts/settings';

function pollAgainAfterTimeout(store, exportId) {
  return store.dispatch(
    actions.storePollTimeout(
      exportId,
      window.setTimeout(
        () => store.dispatch(actions.pollExport(exportId)),
        settings.POLL_INTERVAL
      )
    )
  );
}

function pollExportStatusBulkTimeout(store, exportId, results) {
  return store.dispatch(
    actions.storePollTimeout(
      exportId,
      window.setTimeout(
        () => store.dispatch(actions.pollExportStatusBulk(results, exportId)),
        settings.POLL_INTERVAL
      )
    )
  );
}

export default store => next => action => {
  switch (action.type) {
    case actions.EXPORT_DOCUMENT_FOLDER.SUCCESS: {
      const { payload: { count, results }, meta: { folder } } = action;

      if (count) {
        pollExportStatusBulkTimeout(store, folder, fromJS(results));
      }
      break;
    }

    case actions.POLL_EXPORT_STATUS_BULK.SUCCESS: {
      const { meta: { folder, exports } } = action;

      if (pollComplete(fromJS(action))) {
        store.dispatch(actions.mergeDocuments(fromJS(exports), folder));
      } else if (pollFailed(fromJS(action))) {
        store.dispatch(actions.clearAllExportTimeouts());
      } else {
        pollExportStatusBulkTimeout(store, folder, fromJS(exports));
      }
      break;
    }

    case actions.MERGE_DOCUMENTS.SUCCESS: {
      const { payload: { id } } = action;

      pollAgainAfterTimeout(store, id);
      break;
    }

    case CLOSE_MODAL:
      store.dispatch(actions.clearAllExportTimeouts());
      break;

    case actions.POLL_EXPORT.REQUEST:
      store.dispatch(removeCloseButtonWhilePolling());
      break;
    case actions.POLL_EXPORT.SUCCESS: {
      const immutableAction = fromJS(action);
      const exportId = immutableAction.getIn(['meta', 'source']);
      if (pollInProgress(immutableAction)) {
        pollAgainAfterTimeout(store, exportId);
      } else if (pollComplete(immutableAction) || pollFailed(fromJS(action))) {
        store.dispatch(actions.clearAllExportTimeouts());
        store.dispatch(addCloseButtonAfterPolling());
      }
      break;
    }
  }

  return next(action);
};
