import React from 'react';
import { connect } from 'react-redux';
import { Map, Set } from 'immutable';

import {
  CALENDAR_PRIORITY_FILTERS,
  PRIORITY_CLASSES,
  PRIORITY_NAMES,
  PRIORITIES,
} from '^/models/constants';
import { switchPractice, setFilter, clearFilter } from '^/actions/actions';
import { loadStats, loadYearToDateStats } from '^/actions/stats';
import { ACTIVITIES } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
import PureComponent from '^/components/common/PureComponent';
import ContentTabset from '^/components/app/content/ContentTabset';
import ContentTab from '^/components/app/content/ContentTab';
import Loading from '^/components/app/content/Loading';
import ActivityStatsAllPractices from '^/components/app/stats/activities/ActivityStatsAllPractices';
import ActivityStatsThisYear from '^/components/app/stats/activities/ActivityStatsThisYear';
import ActivityStatsProgress from '^/components/app/stats/activities/ActivityStatsProgress';
import TaskStatsProgress from '^/components/app/stats/TaskStatsProgress';
import { practiceHasDashboardTasksChart } from '^/stateHelpers';
import { t } from '^/i18n';

export const ACTIVITY_STATS = 'ACTIVITY_STATS';
const EXCLUDE_STATS_FILTER = 'EXCLUDE_STATS_FILTER';
const FILTER_BY_PRIORITY_TITLE = (
  <p className="mb-1">
    {t('activities.filterByPriority', 'Filter by priority')}:
  </p>
);

export class ActivityStats extends PureComponent {
  constructor(props) {
    super(props);
    this.buildPriorityFilter = this.buildPriorityFilter.bind(this);
    this.applyPriorityFilter = this.applyPriorityFilter.bind(this);
    this.renderPriorityFilterCheckboxes = this.renderPriorityFilterCheckboxes.bind(
      this
    );
  }

  componentWillMount() {
    const { practiceId, currentTeamMember } = this.props;
    this.loadActivityStats(practiceId, currentTeamMember);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.practiceId !== this.props.practiceId ||
      nextProps.currentTeamMember !== this.props.currentTeamMember
    ) {
      this.loadActivityStats(nextProps.practiceId, nextProps.currentTeamMember);
    }
  }

  loadActivityStats(practiceId, teamMember) {
    const { model, statsPriorityFilters, hasTasksChart } = this.props;
    const teamMemberId = teamMember && teamMember.get('id');
    if (practiceId) {
      this.props.loadStats(model, practiceId, practiceId, teamMemberId);
      this.props.loadYearToDateStats(
        model,
        practiceId,
        statsPriorityFilters,
        practiceId,
        teamMemberId
      );
      if (hasTasksChart) {
        this.props.loadYearToDateStats(
          'tasks',
          practiceId,
          Map({}),
          undefined,
          teamMemberId
        );
      }
    }
  }

  buildPriorityFilter(event) {
    const { statsPriorityFilters } = this.props;

    const filterKey = event.target.id;
    const filterValue = event.target.value;
    const filterUnchecked = !event.target.checked;

    const statsPriorityFilterSet = statsPriorityFilters.get(filterKey)
      ? Set(statsPriorityFilters.get(filterKey))
      : Set();

    this.props.setFilter(EXCLUDE_STATS_FILTER, filterValue, filterUnchecked);

    if (filterUnchecked) {
      return statsPriorityFilters.set(
        filterKey,
        statsPriorityFilterSet.add(filterValue)
      );
    }

    return statsPriorityFilterSet.size <= 1
      ? statsPriorityFilters.delete(filterKey)
      : statsPriorityFilters.set(
          filterKey,
          statsPriorityFilterSet.delete(filterValue)
        );
  }

  applyPriorityFilter(event) {
    const { model, practiceId, currentTeamMember } = this.props;

    const filters = this.buildPriorityFilter(event);

    this.props.loadYearToDateStats(
      model,
      practiceId,
      filters,
      practiceId,
      currentTeamMember && currentTeamMember.get('id')
    );
  }

  renderPriorityFilterCheckboxes(filters) {
    return CALENDAR_PRIORITY_FILTERS.filter(
      priorityType => priorityType !== PRIORITIES.CRITICAL
    ).map(priorityType => (
      <div key={`priority-filter-${priorityType}`} className="mb-1-2">
        <label>
          <input
            id={'priority'}
            type="checkbox"
            name={priorityType}
            value={priorityType}
            onChange={this.applyPriorityFilter}
            checked={!filters.get(priorityType, false)}
          />
          <span
            className={`mb-1-2 actvities-priority-pill ${PRIORITY_CLASSES[priorityType]}`}
          >
            {PRIORITY_NAMES[priorityType]}
          </span>
        </label>
      </div>
    ));
  }

  renderActivitiesAndTasksCharts() {
    const {
      yearToDateStats,
      yearToDateStatsTasks,
      excludePriorityStatsFilter,
    } = this.props;

    return (
      <div className="row">
        <div className="col-1-2">
          {!yearToDateStats ? (
            <Loading />
          ) : (
            <div>
              <ActivityStatsProgress stats={yearToDateStats} />
              {FILTER_BY_PRIORITY_TITLE}
              <div className="display-flex flex-justify-space-between">
                {this.renderPriorityFilterCheckboxes(
                  excludePriorityStatsFilter
                )}
              </div>
            </div>
          )}
        </div>
        <div className="col-1-2">
          {!yearToDateStatsTasks ? (
            <Loading />
          ) : (
            <TaskStatsProgress stats={yearToDateStatsTasks} />
          )}
        </div>
      </div>
    );
  }

  renderActivitiesChartOnly() {
    const { yearToDateStats, excludePriorityStatsFilter } = this.props;

    return !yearToDateStats ? (
      <Loading />
    ) : (
      <div className="display-flex flex-justify-space-between">
        <div className="width-70">
          <ActivityStatsProgress stats={yearToDateStats} />
        </div>
        <div className="pt-2 pb-2">
          {FILTER_BY_PRIORITY_TITLE}
          {this.renderPriorityFilterCheckboxes(excludePriorityStatsFilter)}
        </div>
      </div>
    );
  }

  render() {
    const {
      stats,
      tabsetName,
      loading,
      practiceId,
      hasTasksChart,
    } = this.props;

    return (
      <ContentTabset name={tabsetName} defaultActiveTab={0} className="mb-1">
        <ContentTab tabsetName={tabsetName} tabId={0} tabName="Progress">
          {hasTasksChart
            ? this.renderActivitiesAndTasksCharts()
            : this.renderActivitiesChartOnly()}
        </ContentTab>
        <ContentTab
          tabsetName={tabsetName}
          tabId={1}
          tabName={t('activities.activitiesThisYear', 'Activities this year')}
        >
          {loading ? <Loading /> : <ActivityStatsThisYear stats={stats} />}
        </ContentTab>
        <ContentTab
          tabsetName={tabsetName}
          tabId={2}
          tabName={t(
            'activities.allPracticesActivities',
            "All practices' activities"
          )}
        >
          {loading ? (
            <Loading />
          ) : (
            <ActivityStatsAllPractices
              stats={stats}
              currentPracticeId={practiceId}
              switchPractice={this.props.switchPractice}
            />
          )}
        </ContentTab>
      </ContentTabset>
    );
  }
}

export function mapStateToProps(state, props) {
  const { practiceId } = props;
  const model = ACTIVITIES;
  const yearToDateStats = state.yearToDateStats;
  const excludePriorityStatsFilter = state.filterSpec.get(
    EXCLUDE_STATS_FILTER,
    Map()
  );
  const statsPriorityFilters = yearToDateStats
    ? Map(yearToDateStats.get('filters'))
    : Map();
  const response = state.responses.getIn(['loadStats', practiceId]);
  const stats = state.stats.get(practiceId);

  return {
    stats,
    yearToDateStats: state.yearToDateStats.get(practiceId),
    yearToDateStatsTasks: state.yearToDateStats.get('tasks'),
    loading: isPending(response) || !stats,
    model,
    statsPriorityFilters,
    excludePriorityStatsFilter,
    tabsetName: ACTIVITY_STATS,
    currentTeamMember: state.currentTeamMember,
    hasTasksChart: practiceHasDashboardTasksChart(state),
  };
}

export default connect(mapStateToProps, {
  setFilter,
  loadStats,
  clearFilter,
  loadYearToDateStats,
  switchPractice,
})(ActivityStats);
