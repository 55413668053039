import i18next, { t } from 'i18next';
import ICU from 'i18next-icu';

// eslint-disable-next-line dabapps/no-relative-parent-import
import translationsEN from '../../../locales/en/translations.json';
// eslint-disable-next-line dabapps/no-relative-parent-import
import translationsNL from '../../../locales/nl/translations.json';

const resources = {
  en: {
    translation: translationsEN,
  },
  nl: {
    translation: translationsNL,
  },
};

// eslint-disable-next-line import/no-named-as-default-member
i18next.use(ICU).init({
  lng: window.USER_PREFERRED_LANGUAGE,
  fallBackLng: 'en',
  debug: false,
  ns: ['translation'],
  defaultNS: 'translation',
  supportedLngs: ['en', 'nl'],
  resources,
  interpolation: {
    prefix: '{',
    suffix: '}',
  },
});

export { t };

export default i18next;
