import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { List } from 'immutable';

import { t } from '^/i18n';
import { makeStaticPath } from '^/utils';
import { logout, setCollapse } from '^/actions/actions';
import { getCollection, getAllCollection } from '^/actions/collections';
import { getGroupDocumentsAlertsCount } from '^/actions/groupDocuments';
import {
  ALERTS,
  TEMPLATE_DOCUMENT_VERSION,
  STAFF_ALERTS,
  DASHBOARD_DOCUMENTS_READ_REQUESTS,
} from '^/consts/collectionKeys';

import { ALERT_TYPES } from '^/models/alerts';
import { getStaffPracticeTypeDisplay } from '^/models/staffPractice';
import { isDemoUser } from '^/models/user';
import {
  loadDashboardActivitiesAndUpdatedTemplates,
  loadDocumentReviewsCount,
} from '^/actions/dashboardActivitiesAndUpdatedTemplates';
import { openConfirmUpgradeDemoModal } from '^/actions/modals';
import { shouldLoadTaskListAndUpdatedNewTemplates } from '^/consts/reloadRules';
import {
  isOnDashboardPage,
  isOnGroupAdminPage,
  isOnUserProfilePage,
} from '^/consts/redirectRules';
import { SITE_URL } from '^/consts/single-sign-on';

import PureComponent from '^/components/common/PureComponent';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import Icon from '^/components/app/content/Icon';
import LoggedInAs from '^/components/app/nav/LoggedInAs';
import ViewSwitcher from '^/components/app/nav/ViewSwitcher';
import CurrentPractice from './CurrentPractice';
import Search from '^/components/app/nav/search/Search';
import {
  CONFIRM_LEAVE_PAGE_MESSAGE,
  name,
} from '^/components/app/template-documents/TemplateDocumentPage';
import Tabs, { NAV_ID } from './Tabs';
import DemoBanner from './DemoBanner';
import {
  hasAccessToAdoptedGroupDocuments,
  hasAccessToGroupDashboard,
  hasAccessToGroupNewsTriage,
  hasAccessToGroupPracticeInteractionReport,
  isLoggedOnUserAdminOfCurrentGroup,
  isLoggedOnUserInsightsOnlyGroupUser,
  isImpersonateUser,
  practiceHasAccessToDigitalTools,
  practiceGroupHasAccessToGroupConfiguration,
  practiceHasAccessToDocumentReviews,
  practiceHasDocumentsToReadAccess,
  practiceHasReportsTabAccess,
} from '^/stateHelpers';

import { selectGroupDocumentsAlertsCount } from '^/selectors/groupDocuments';
import AppSwitcher from './app-switcher';

export const NAV_ID_SEARCH = 'NAV_ID_SEARCH';
export const VIEW_ID_SWITCHER = 'VIEW_ID_SWITCHER';
export const APP_SWITCHER_ID = 'APP_SWITCHER_ID';

function pathIdIsExpanded(expandedUiComponents, currentPath) {
  const uuidFromCurrentPath = currentPath.slice(-37, currentPath.length - 1);
  const UUID_REGEX = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return UUID_REGEX.test(uuidFromCurrentPath)
    ? expandedUiComponents.contains(name(uuidFromCurrentPath))
    : false;
}

export class Nav extends PureComponent {
  constructor(props) {
    super(props);
    this.checkAndLogout = this.checkAndLogout.bind(this);
  }

  componentWillMount() {
    this.loadTaskListAndUpdatedNewTemplates(this.props);
    this.props.getCollection(STAFF_ALERTS);
    this.props.getGroupDocumentsAlertsCount();
  }

  componentWillReceiveProps(nextProps) {
    const { searchOpen, viewSwitcherOpen, menuOpen } = this.props;
    this.loadTaskListAndUpdatedNewTemplates(nextProps, this.props);

    // collapse search if menu or viewSwitcher opened
    if (searchOpen && (nextProps.menuOpen || nextProps.viewSwitcherOpen)) {
      this.props.setCollapse(NAV_ID_SEARCH, true);
    }

    // collapse menu if search or viewSwitche opened
    if (menuOpen && (nextProps.searchOpen || nextProps.viewSwitcherOpen)) {
      this.props.setCollapse(NAV_ID, true);
    }

    // collapse viewSwitcher if menu or searchOpen opened
    if (viewSwitcherOpen && (nextProps.searchOpen || nextProps.menuOpen)) {
      this.props.setCollapse(VIEW_ID_SWITCHER, true);
    }
  }

  loadTaskListAndUpdatedNewTemplates(props, oldProps = {}) {
    if (shouldLoadTaskListAndUpdatedNewTemplates(props, oldProps)) {
      const { practiceId, currentTeamMember } = props;
      this.props.loadDashboardActivitiesAndUpdatedTemplates(
        practiceId,
        currentTeamMember && currentTeamMember.get('id')
      );
      if (props.hasDocumentsToReadAccess) {
        this.props.getAllCollection(
          DASHBOARD_DOCUMENTS_READ_REQUESTS,
          practiceId &&
            currentTeamMember && {
              additionalParams: {
                practice: practiceId,
                user: currentTeamMember.get('id'),
              },
            }
        );
      }
      this.props.getCollection(ALERTS, {
        filters: { type: ALERT_TYPES.NEW_OR_UPDATED_DOCUMENT },
      });
      if (props.hasDocumentsToReviewAccess) {
        this.props.loadDocumentReviewsCount();
      }
    }
  }

  checkAndLogout() {
    if (this.props.isImpersonateUser) {
      window.location.replace(`${SITE_URL}/impersonate/stop/`);
      return;
    }
    if (this.props.isEditing) {
      if (window.confirm(CONFIRM_LEAVE_PAGE_MESSAGE)) {
        this.props.logout();
      }
    } else {
      this.props.logout();
    }
  }

  render() {
    const {
      userProfile,
      navDisabled,
      taskList,
      menuOpen,
      searchOpen,
      viewSwitcherOpen,
      isOnDashboard,
      isOnGroupAdmin,
      userIsGroupAdmin,
      hasGroupDashboardAccess,
      hasGroupDocumentsAccess,
      alertsCount,
      groupDocumentsAlertsCount,
      hasAccessToDigitalTools,
      practiceId,
      hasAccessToGroupConfiguration,
      hasAccessToReports,
      hasGroupNewsTriageAccess,
      hasGroupPracticeInteractionReportAccess,
      userIsInsightsOnlyGroupAdmin,
    } = this.props;

    const appSwitcherConfig = {
      icon: '/static/images/icons/app-switcher-icon.svg',
      agilio: {
        icon: '/static/images/agilio-a.png',
      },
      iPlan: {
        icon: '/static/images/iplan.png',
        url: window.APP_SWITCHER_IPLAN_URL,
        readmore: 'https://agiliosoftware.com/dental/iplan/',
      },
      iTeam: {
        icon: '/static/images/iteam.png',
        url: window.APP_SWITCHER_ITEAM_URL,
        readmore: 'https://agiliosoftware.com/dental/iteam/',
      },
      iService: {
        icon: '/static/images/iservice.png',
        url: window.APP_SWITCHER_ISERVICE_URL,
        readmore: 'https://agiliosoftware.com/dental/iservice/',
      },
      iLearn: {
        icon: '/static/images/ilearn.png',
        url: window.APP_SWITCHER_ILEARN_URL,
        readmore: 'https://agiliosoftware.com/dental/ilearn/',
      },
      iShop: {
        icon: '/static/images/ishop.png',
        url: window.APP_SWITCHER_ISHOP_URL,
        readmore: '',
      },
    };

    const { USE_APP_SWITCHER } = window;

    return (
      <div className="nav">
        <div className="nav-logo">
          <div className="wrapper relative">
            <div className="row">
              {USE_APP_SWITCHER && (
                <CollapseButton
                  collapseId={APP_SWITCHER_ID}
                  initiallyCollapsed
                  collapseOnMount
                  className="collapse-view-switcher pull-left pt-1-3 pl-1-3"
                >
                  <AppSwitcher config={appSwitcherConfig} />
                </CollapseButton>
              )}

              <CollapseButton
                collapseId={VIEW_ID_SWITCHER}
                initiallyCollapsed
                collapseOnMount
                className="collapse-view-switcher pull-left pt-1-2 pl-1-2"
              >
                <button className="view-switcher-button">
                  <Icon
                    type="ellipsis-h-solid"
                    className="view-switcher-icon"
                  />
                </button>
              </CollapseButton>

              {!isOnGroupAdmin && (
                <CollapseButton
                  collapseId={NAV_ID_SEARCH}
                  initiallyCollapsed
                  collapseOnMount
                  className="collapse-search pull-left pt-1-2 pl-1-2"
                >
                  <button className="search-button">
                    <Icon type="search" className="search-button-icon" />
                  </button>
                </CollapseButton>
              )}

              <div className="brand-container">
                {USE_APP_SWITCHER && <AppSwitcher config={appSwitcherConfig} />}
                <Link className="brand" to="/page/dashboard/">
                  <img
                    src={makeStaticPath('images/brand/logo-color.svg')}
                    className="brand-logo ml-1"
                  />
                  <img
                    src={makeStaticPath('images/brand/logo-color.svg')}
                    className={classNames('brand-logo-mobile', {
                      left: !isOnGroupAdmin,
                    })}
                  />
                </Link>
              </div>

              <CollapseButton
                collapseId={NAV_ID}
                initiallyCollapsed
                collapseOnMount
                className="collapse-nav pull-right pr-1 pt-1-2"
              >
                <button className="nav-menu-button" tabIndex="0">
                  <span>
                    {t('common.menu', 'menu')}
                    {menuOpen ? (
                      <span
                        aria-hidden="true"
                        className="nav-menu-button-indicator"
                      >
                        &times;
                      </span>
                    ) : (
                      <span
                        aria-hidden="true"
                        className="nav-menu-button-indicator open"
                      >
                        &#9776;
                      </span>
                    )}
                  </span>
                </button>
              </CollapseButton>
            </div>
          </div>
        </div>
        <div className="nav-strip">
          <div className="wrapper relative">
            <div className="row">
              <Collapse collapseId={NAV_ID}>
                <div
                  className={classNames(
                    'nav-strip-content',
                    isOnGroupAdmin ? 'no-margin-right' : ''
                  )}
                >
                  {userIsGroupAdmin &&
                    hasGroupDashboardAccess &&
                    !menuOpen &&
                    !userIsInsightsOnlyGroupAdmin && (
                      <ViewSwitcher isOnGroupAdminPage={isOnGroupAdmin} />
                    )}

                  <LoggedInAs
                    userName={
                      userProfile.getIn(['staffdetail', 'full_name']) ||
                      userProfile.get('role')
                    }
                    userType={getStaffPracticeTypeDisplay(
                      userProfile.get('user_type_in_current_practice'),
                      userIsGroupAdmin
                    )}
                    logout={this.checkAndLogout}
                  />

                  {!isOnGroupAdmin && (
                    <div className="nav-currently-viewing">
                      <CurrentPractice />
                    </div>
                  )}
                </div>
              </Collapse>

              {viewSwitcherOpen &&
                hasGroupDashboardAccess &&
                !userIsInsightsOnlyGroupAdmin && (
                  <ViewSwitcher isOnGroupAdminPage={isOnGroupAdmin} />
                )}

              {searchOpen && !isOnGroupAdmin && <Search />}
              {!isOnGroupAdmin && (
                <Search className="nav-strip-search desktop" />
              )}
            </div>
          </div>
        </div>

        <Tabs
          navDisabled={navDisabled}
          overDueActivityCount={
            taskList && taskList.get('OVERDUE', List()).size
          }
          alertsCount={alertsCount}
          groupDocumentsAlertsCount={groupDocumentsAlertsCount}
          isOnGroupAdminPage={isOnGroupAdmin}
          hasGroupDocumentsAccess={hasGroupDocumentsAccess}
          hasAccessToDigitalTools={hasAccessToDigitalTools}
          hasAccessToGroupConfiguration={hasAccessToGroupConfiguration}
          hasAccessToReports={hasAccessToReports}
          hasGroupNewsTriageAccess={hasGroupNewsTriageAccess}
          hasGroupPracticeInteractionReportAccess={
            hasGroupPracticeInteractionReportAccess
          }
          practiceId={practiceId}
        />

        {isDemoUser(userProfile) && !isOnDashboard && (
          <DemoBanner onClickSignup={this.props.openConfirmUpgradeDemoModal} />
        )}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  const templateDocumentVersion = state.items.get(TEMPLATE_DOCUMENT_VERSION);
  const userProfile = state.userProfile;
  const isEditingDocument = pathIdIsExpanded(
    state.ui.get('expandedUiComponents'),
    state.routing.location.pathname
  );
  let isWorkingVersion = false;
  if (templateDocumentVersion && userProfile) {
    isWorkingVersion =
      templateDocumentVersion.getIn(['working_version', 'user', 'id']) ===
      userProfile.get('id');
  }
  return {
    isOnDashboard: isOnDashboardPage(window.location.pathname || ''),
    isOnGroupAdmin:
      isOnGroupAdminPage(window.location.pathname || '') ||
      (isLoggedOnUserInsightsOnlyGroupUser(state) &&
        isOnUserProfilePage(window.location.pathname)),
    userProfile: state.userProfile,
    searchOpen: !state.ui.getIn(['collapse', NAV_ID_SEARCH]),
    viewSwitcherOpen: !state.ui.getIn(['collapse', VIEW_ID_SWITCHER]),
    menuOpen: !state.ui.getIn(['collapse', NAV_ID]),
    navDisabled: state.ui.get('navDisabled'),
    taskList: state.taskList,
    response: state.responses.get('loadDashboardActivitiesAndUpdatedTemplates'),
    practiceId: state.currentPractice && state.currentPractice.get('id'),
    currentTeamMember: state.currentTeamMember,
    alertsCount: state.collections.getIn([STAFF_ALERTS, 'count'], 0),
    alertsResponse: state.responses.getIn(['getCollection', ALERTS]),
    groupDocumentsAlertsCount: selectGroupDocumentsAlertsCount(state),
    isEditing: isEditingDocument || isWorkingVersion,
    userIsGroupAdmin: isLoggedOnUserAdminOfCurrentGroup(state),
    userIsInsightsOnlyGroupAdmin: isLoggedOnUserInsightsOnlyGroupUser(state),
    hasGroupDashboardAccess: hasAccessToGroupDashboard(state),
    hasGroupNewsTriageAccess: hasAccessToGroupNewsTriage(state),
    hasGroupPracticeInteractionReportAccess: hasAccessToGroupPracticeInteractionReport(
      state
    ),
    hasAccessToGroupConfiguration: practiceGroupHasAccessToGroupConfiguration(
      state
    ),
    hasGroupDocumentsAccess: hasAccessToAdoptedGroupDocuments(state),
    isImpersonateUser: isImpersonateUser(state),
    hasAccessToDigitalTools: practiceHasAccessToDigitalTools(state),
    hasDocumentsToReviewAccess: practiceHasAccessToDocumentReviews(state),
    hasDocumentsToReadAccess: practiceHasDocumentsToReadAccess(state),
    hasAccessToReports: practiceHasReportsTabAccess(state),
  };
}

export default connect(mapStateToProps, {
  logout,
  loadDashboardActivitiesAndUpdatedTemplates,
  setCollapse,
  openConfirmUpgradeDemoModal,
  getCollection,
  getGroupDocumentsAlertsCount,
  loadDocumentReviewsCount,
  getAllCollection,
})(Nav);
