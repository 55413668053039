import { t } from '^/i18n';
import { CYCLE_LABELS_MAPPED_TO_INDEX } from '^/components/app/groups/insights/constants';
import {
  MOMENT_UTC_TODAY,
  MOMENT_UTC_A_YEAR_AGO,
  formatDate,
  getQueryParamsFromString
} from '^/utils';

export const convertDatesToPeriod = (dateFrom, dateTo) => {
  if (dateFrom === MOMENT_UTC_A_YEAR_AGO && dateTo === MOMENT_UTC_TODAY) {
    return t('groups.insights.overLast12Months', 'over the last 12 months');
  }
  return `between ${formatDate(dateFrom)} and ${formatDate(dateTo)}`;
};

export const convertEndDateToPeriod = (dateTo) => {
  return `${t('common.until', 'until')} ${dateTo ? formatDate(dateTo) : t('groups.insights.today', 'today')}`;
};

export function getTimePeriodLabel(dateFrom, dateTo, cycleIndex) {
  if (dateFrom && dateTo) {
    return convertDatesToPeriod(dateFrom, dateTo)
  }
  if (cycleIndex) {
    return `over the ${CYCLE_LABELS_MAPPED_TO_INDEX[cycleIndex]}`;
  }
}

export const getSubGroupFilterFromPath = () => {
  const queryParams = getQueryParamsFromString(window.location.search);
  return queryParams.sub_group;
}

export const getCompletedActivitiesCountryFromPath = () => {
  const queryParams = getQueryParamsFromString(window.location.search);
  return queryParams.country;
}

export const selectTemplateActivitiesOptions = (templateActivities) => {
  return Array.from(templateActivities.map(
    templateActivity => ({value: templateActivity.get('template_id'), label: templateActivity.get('template_name')})
  ));
}

export const selectedTemplateActivityLabel = (templateActivitiesOptions, selectedValue) => {
  const templateActivity = templateActivitiesOptions.filter(data => {return data.value === selectedValue})[0]
  return templateActivity ? templateActivity.label : '--'
}
