import React from 'react';
import { connect } from 'react-redux';

import { t } from '^/i18n';
import { isPending, hasSucceeded } from '^/consts/responseStates';
import { pollExport, resetResponse } from '^/actions/actions';
import { pollComplete, pollInProgress } from '^/models/export';
import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';


export class PollDownload extends PureComponent {

  componentWillMount() {
    this.props.resetResponse('pollExport');
    this.props.startPolling();
  }

  componentWillUnmount() {
    window.clearTimeout(this.props.pollTimeout);
  }

  render() {
    const { response, onRetry, className } = this.props;
    const fileType = this.props.fileType || 'PDF';

    if (isPending(response) || (hasSucceeded(response) && pollInProgress(response))) {
      return (
        <a className={className || 'btn btn-default small indented ml-1-2'} disabled>
          <Icon type="cog" className="button-icon rotate" /> {t('common.generating', 'Generating')}{' '}{fileType}...
        </a>
      );
    }

    if (hasSucceeded(response) && pollComplete(response)) {
      const payload = response.get('payload');
      return (
        <a
          className={className || 'btn btn-save small indented ml-1-2'}
          href={payload.get('download_url')}
          download={payload.get('filename', true)}
        >
          <Icon type="check" className="button-icon" /> {t('common.button.download', 'Download PDF')}{' '}{fileType}
        </a>
      );
    }

    return (
      <span>
        <a className={className || 'btn btn-default small indented ml-1-2'} onClick={onRetry}>
          <Icon type="cloud-download" className="button-icon" />
          {t('common.button.tryAgain', 'Try Again')}
        </a>

        <p className="pull-right cr alert-error">
          {t('activities.pdfFailMsg', 'Unable to access PDF. Please try again later.')}
        </p>
      </span>
    );
  }
}

export const mapStateToProps = (state, props) => ({
  response: state.responses.getIn(['pollExport', props.exportId]),
  pollTimeout: state.pollExportTimeouts.get(props.exportId),
});

export const mapDispatchToProps = (dispatch, props) => ({
  startPolling: () => dispatch(pollExport(props.exportId)),
  resetResponse: () => dispatch(resetResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps) (PollDownload);
