import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';
import PictogramCheckboxGroup from '^/components/app/digital-tools/records/digital-records/forms/PictogramCheckboxGroup';

import { hasSucceeded } from '^/consts/responseStates';
import { uploadFile } from '^/actions/actionSequences';
import { setCollapse } from '^/actions/actions';
import {
  COSHH_SAFETY_PICTOGRAM_CHOICES,
  renderTextAreaField,
  renderTextField,
  renderRelatedTasksField,
  renderMultipleFileUploadField,
} from '^/components/app/digital-tools/records/digital-records/forms/FormFields';
import { extraInfo } from '^/components/app/digital-tools/records/digital-records/forms/CoshHoverInfo';
import { t } from '^/i18n';

export const COSHH_FORM = 'COSHH_FORM';

export class CoshhForm extends PureComponent {
  render() {
    const {
      handleSubmit,
      fields,
      response,
      isEditing,
      isArchived,
      staff_tasks,
      handleArchive,
      archiveDigitalRecordResponse,
      updateDigitalRecordResponse,
      createDigitalRecordResponse,
      uploadFileResponse,
    } = this.props;

    const {
      type,
      product_name,
      used_by,
      used_for,
      location,
      amount_used,
      usage_frequency,
      usage_conditions,
      signal_word,
      hazard_statement,
      risks_to_health,
      precautionary_statement,
      safety_measures,
      ppe_required,
      first_aid_proceedures,
      emergency_proceedures,
      special_storage,
      disposal_measures,
      health_monitoring,
      additional_info,
      related_files,
      safety_pictograms,
      notes,
    } = fields;

    return (
      <form onSubmit={handleSubmit}>
        <div className="mt-2">
            <div className="mt-2">
              {renderTextField(type, `${t('common.productType', 'Product Type')}:*`, response, isArchived, extraInfo.productType)}
              {renderTextField(product_name, `${t('common.productName', 'Product Name')}:`, response, isArchived, extraInfo.productName)}
              {renderTextField(used_by, `${t('common.usedBy', 'Used by')}:`, response, isArchived, extraInfo.usedBy)}
              {renderTextField(used_for, t('digitalTools.forms.coshhForm.usedFor', 'Used for:'), response, isArchived, extraInfo.usedFor)}
              {renderTextField(location, t('digitalTools.forms.coshhForm.location', 'Location:'), response, isArchived, extraInfo.location)}
              {renderTextField(amount_used, t('digitalTools.forms.coshhForm.amountUsed', 'How much is used:'), response, isArchived, extraInfo.amountUsed)}
              {renderTextField(usage_frequency, t('digitalTools.forms.coshhForm.usageFrequency', 'How often is it used:'), response, isArchived, extraInfo.usageFrequency)}
              {renderTextField(usage_conditions, t('digitalTools.forms.coshhForm.usageConditions', 'How is it used:'), response, isArchived, extraInfo.usageConditions)}
              {renderTextField(signal_word, t('digitalTools.forms.coshhForm.signalWord', 'Signal word:'), response, isArchived, extraInfo.signalWord)}
              {renderTextAreaField(hazard_statement, t('digitalTools.forms.coshhForm.hazardStatement', 'Hazard Statement(s):'), response, isArchived, extraInfo.hazardStatement)}
              {renderTextAreaField(risks_to_health, t('digitalTools.forms.coshhForm.risksToHealth', 'Risks to health:'), response, isArchived, extraInfo.risksToHealth)}
              {renderTextAreaField(precautionary_statement, t('digitalTools.forms.coshhForm.precautionaryStatement', 'Precautionary statements(s):'), response, isArchived, extraInfo.precautionaryStatement)}
              {renderTextAreaField(safety_measures, t('digitalTools.forms.coshhForm.safetyMeasures', 'Safety measures:'), response, isArchived, extraInfo.safetyMeasures)}
              {renderTextAreaField(ppe_required, t('digitalTools.forms.coshhForm.ppeRequired', 'PPE Required:'), response, isArchived, extraInfo.ppeRequired)}
              {renderTextAreaField(first_aid_proceedures, t('digitalTools.forms.coshhForm.firstAidProceedures', 'First Aid Procedures:'), response, isArchived, extraInfo.firstAidProceedures)}
              {renderTextAreaField(emergency_proceedures, t('digitalTools.forms.coshhForm.emergencyProceedures', 'Emergency Procedures:'), response, isArchived, extraInfo.emergencyProceedures)}
              {renderTextAreaField(special_storage, t('digitalTools.forms.coshhForm.specialStorage', 'Special storage:'), response, isArchived, extraInfo.specialStorage)}
              {renderTextAreaField(disposal_measures, t('digitalTools.forms.coshhForm.disposalMeasures', 'Special disposal measures:'), response, isArchived, extraInfo.disposalMeasures)}
              {renderTextAreaField(health_monitoring, t('digitalTools.forms.coshhForm.healthMonitoring', 'Health surveillance / monitoring needed:'), response, isArchived, extraInfo.healthMonitoring)}
              {renderTextAreaField(additional_info, t('digitalTools.forms.coshhForm.additionalInfo', 'Additional information:'), response, isArchived, extraInfo.additionalInfo)}
              <PictogramCheckboxGroup
                field={safety_pictograms}
                display={t('digitalTools.forms.coshhForm.selectPictograms', 'Select pictograms as shown on the product or safety data sheet:')}
                response={response}
                options={COSHH_SAFETY_PICTOGRAM_CHOICES}
                isArchived={isArchived}
              />
              {renderMultipleFileUploadField(
                related_files,
                t('digitalTools.forms.coshhForm.uploadSafetyDataSheet', 'Upload safety data sheet:'),
                handleSubmit,
                this.props.uploadFile,
                uploadFileResponse,
                COSHH_FORM,
                isArchived
              )}
              {renderTextAreaField(notes, t('digitalTools.forms.coshhForm.summary', 'Summary'), response, isArchived)}
              {renderRelatedTasksField(staff_tasks, isArchived)}
            </div>
        </div>
        {!isEditing && !isArchived &&
          <div className="form-group">
            <div className="col-1-3">
              <LiveButton
                pendingMessage={"Creating..."}
                response={createDigitalRecordResponse}
                className="btn-default pd-2"
              >
                { t('common.button.save', 'Save') }
              </LiveButton>
            </div>
          </div>
        }
        {!isArchived && isEditing &&
          <div className="form-group">
            <div className="col-2-3">
              <LiveButton
                pendingMessage={"Archiving..."}
                response={archiveDigitalRecordResponse}
                className="btn-warning pd-2"
                onClick={handleArchive}
              >
                { t('common.button.archive', 'Archive') }
              </LiveButton>
            </div>
            <div className="col-1-3">
              <LiveButton
                pendingMessage={"Saving..."}
                response={updateDigitalRecordResponse}
                className="btn-default pd-2"
              >
                { t('common.button.save', 'Save') }
              </LiveButton>
            </div>
          </div>
        }
        {hasSucceeded(updateDigitalRecordResponse) && (
          <div className="form-group mb-2">
            <p className="alert mt-1 alert-success">{ t('common.label.successfullyUpdated', 'Successfully updated!') }</p>
          </div>
          )
        }
        {hasSucceeded(archiveDigitalRecordResponse) && (
          <div className="form-group mb-2">
            <p className="alert mt-1 alert-warning">{ t('common.label.successfullyArchived', 'Successfully archived!') }</p>
          </div>
          )
        }
      </form>
    );
  }
};

CoshhForm.propTypes = {
  fields: React.PropTypes.object,
  handleSubmit: React.PropTypes.func.isRequired,
  isEditing: React.PropTypes.bool,
  isArchived: React.PropTypes.bool,
  response: ImmutablePropTypes.map,
  archiveDigitalRecordResponse: ImmutablePropTypes.map,
  updateDigitalRecordResponse: ImmutablePropTypes.map,
};

export function validate(values) {
  const {
    type,
  } = values;

  const errors = {};


  if (!type) {
    errors.type = t('digitalTools.forms.coshhForm.errors.productTypeRequired', 'Please provide a Product type.');
  }

  return errors;
}

export function mapStateToProps(state) {
  return {
    uploadFileResponse: state.responses.get('uploadFile'),
  };
}

export const FormifiedEventForm = reduxForm({
  form: COSHH_FORM,
  fields: [
    'type',
    'product_name',
    'used_by',
    'used_for',
    'location',
    'amount_used',
    'usage_frequency',
    'usage_conditions',
    'signal_word',
    'hazard_statement',
    'risks_to_health',
    'precautionary_statement',
    'safety_measures',
    'ppe_required',
    'first_aid_proceedures',
    'emergency_proceedures',
    'special_storage',
    'disposal_measures',
    'health_monitoring',
    'additional_info',
    'related_files',
    'assign_to',
    'safety_pictograms',
    'notes',
  ],
  validate,
})(CoshhForm);

export default connect(
  mapStateToProps,
  { uploadFile, setCollapse }
)(FormifiedEventForm);
