import React, { PropTypes } from 'react';

export const FilterBar = ({ 
  filterComponents, filterClassNames, onApplyClicked, onClearClicked, isDisabled,
  applyLabel, clearLabel,
}) => {
  const wrapperClasses = filterClassNames || ["col-1-4 mt-1-2"];
  return (
    <div className="row clearfix">
      {filterComponents.map((cpnt, index) => 
        <div 
          className={wrapperClasses[index % wrapperClasses.length]} 
          key={index}
        >
          {cpnt}
        </div>
      )}
      {onApplyClicked &&
        <div className="col-1-12">
          <label className="display-block light-text">&nbsp;</label>
          <button
            className="btn btn-default"
            onClick={onApplyClicked}
            disabled={isDisabled}
          >
            {applyLabel || "Filter"}
          </button>
        </div>
      }
      {onClearClicked &&
        <div className="col-1-12">
          <label className="display-block light-text">&nbsp;</label>
          <button
            className="btn btn-default"
            onClick={onClearClicked}
            disabled={isDisabled}
          >
            {clearLabel || "Clear"}
          </button>
        </div>
      }
    </div>
  );
}

FilterBar.propTypes = {
  filterComponents: React.PropTypes.arrayOf(PropTypes.node).isRequired,
  filterClassNames: React.PropTypes.arrayOf(React.PropTypes.string),
  onApplyClicked: React.PropTypes.func,
  onClearClicked: React.PropTypes.func,
  isDisabled: React.PropTypes.bool,
  applyLabel: React.PropTypes.string,
  clearLabel: React.PropTypes.string,
}

export default FilterBar;