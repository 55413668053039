import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const SelectFilter = ({ 
    id, filterKey, options, label, isDisabled, selectedValue, onChange
}) => {
  return (
    <div>
      <label className="display-block mb-1-4 light-text" htmlFor={id}>
        {label}
      </label>
      <select
        className="form-select full-width"
        id={id}
        onChange={event => onChange(filterKey, event.target.value)}
        disabled={isDisabled}
        value={selectedValue}
      >
        <option value="">All</option>
        {options.map(option => (
          <option
            key={`${option.value}`}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectFilter.propTypes = {
  id: React.PropTypes.string,
  filterKey: React.PropTypes.string.isRequired,
  options: ImmutablePropTypes.listOf(React.PropTypes.object),
  label: React.PropTypes.string,
  selectedValue: React.PropTypes.any,
  onChange: React.PropTypes.func.isRequired,
};

export default SelectFilter;
