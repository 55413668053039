export const SectorTypes = {
    DENTAL: 'DENTAL',
    GP: 'GP',
    MODALITY: 'MODALITY',
    HOSPITAL: 'HOSPITAL',
    PHARMACY: 'PHARMACY',
    VETERINARY: 'VETERINARY',
    AGILIO_INTERNAL: 'AGILIO_INTERNAL',
    PRIMARY_CARE_NETHERLANDS: 'PRIMARY_CARE_NETHERLANDS'
}
