import EditPracticeModal from '^/components/app/practices/modals/EditPracticeModal';
import EditGroupDetailsModal from '^/components/app/groups/group-details/modals/EditGroupDetailsModal';

import MaintenanceModal from '^/components/common/modals/MaintenanceModal';

// App modals
import AddNewOrExistingTeamMemberTabset from '^/components/app/users/modals/AddNewOrExistingTeamMemberTabset';
import ExportModalContent from '^/components/admin/export/ExportModalContent';
import CreateTask from '^/components/app/tasks/manage/CreateTask';
import CreateEditGroupTasksModal from '^/components/app/groups/group-tasks/CreateEditGroupTasksModal';
import ArchiveGroupTasksModal from '^/components/app/groups/group-tasks/ArchiveGroupTasksModal';
import EditMemberModalContent from '^/components/app/practices/modals/EditMemberModalContent';
import EditMemberActivitiesModal from '^/components/app/practices/modals/EditMemberActivitiesModal';
import RetireMemberModal from '^/components/app/practices/modals/RetireMemberModal';
import ReinstateMemberModal from '^/components/app/practices/modals/ReinstateMemberModal';
import SuspendMemberModal from '^/components/app/practices/modals/SuspendMemberModal';
import SetupCycleDateFormModal from '^/components/app/users/profile/my-practices/SetupCycleDateFormModal';
import UploadActivityTaskAssigneeFileModal from '^/components/app/activities/documents/modals/UploadActivityTaskAssigneeFileModal';
import ConfirmDemoResetModal from '^/components/app/dashboard/ConfirmDemoResetModal';
import DocumentToReadModal from '^/components/app/dashboard/DocumentToReadModal';
import ConfirmUpgradeDemoModal from '^/components/app/dashboard/ConfirmUpgradeDemoModal';
import RemoveUploadedActivityFileModal from '^/components/app/activities/documents/modals/RemoveUploadedActivityFileModal';
import TogglePrivacyUploadedActivityTaskFileModal from '^/components/app/activities/documents/modals/TogglePrivacyUploadedActivityTaskFileModal';
import MarkTaskCompleteModal from '^/components/app/tasks/modals/MarkTaskCompleteModal';
import TasksLeftUndeleted from '^/components/app/tasks/modals/TasksLeftUndeleted';
import PracticeAdoptionModal from '^/components/app/template-documents/modals/PracticeAdoptionModal';
import ExitConfirmationModal from '^/components/app/template-documents/modals/ExitConfirmationModal';
import ComparisonEditorExitConfirmationModal from '^/components/app/template-documents/modals/ComparisonEditorExitConfirmationModal';
import RestoreAdoptedVersionModal from '^/components/app/template-documents/modals/RestoreAdoptedVersionModal';
import NameDocumentCopyModal from '^/components/app/template-documents/modals/NameDocumentCopyModal';
import CreateGroupFolderModal from '^/components/app/groups/group-documents/CreateGroupFolderModal';
import PublishGroupDocumentModal from '^/components/app/groups/group-documents/PublishGroupDocumentModal';
import GroupTemplateDocumentEditModal from '^/components/app/groups/group-documents/GroupTemplateDocumentEditModal';
import GroupTemplateDocumentNewVersionModal from '^/components/app/groups/group-documents/GroupTemplateDocumentNewVersionModal';
import DocumentFolderExportModal from '^/components/app/template-documents/modals/DocumentFolderExportModal';
import DeleteFolderConfirmationModal from '^/components/app/template-documents/modals/DeleteFolderConfirmationModal';
import RemoveUploadedDocumentModal from '^/components/app/template-documents/modals/RemoveUploadedDocumentModal';
import ChangeUploadedTaskFileFolderModal from '^/components/app/activities/documents/modals/ChangeUploadedTaskFileFolderModal';
import ReassignConfirmationModal from '^/components/app/practices/modals/ReassignConfirmationModal';
import DeleteHealthcheckModal from '^/components/app/healthcheck/delete/modal';
import DeleteAuditModal from '^/components/app/digital-tools/audit/delete/modal';
import CompleteHealthcheckConfirmModal from '^/components/app/healthcheck/complete-healthcheck-confirm-modal'
import ConfirmAuditStageModal from '^/components/app/digital-tools/audit/complete-modal';
import ExitRecordFormConfirmationModal from '^/components/app/digital-tools/records/digital-records/ExitRecordFormConfirmationModal';

// Admin modals
import DiscardWorkingVersionModal from '^/components/admin/template-documents/modals/DiscardWorkingVersionModal';
import PublishAsCurrentVersionModal from '^/components/admin/template-documents/modals/PublishAsCurrentVersionModal';
import PublishAsNewVersionModal from '^/components/admin/template-documents/modals/PublishAsNewVersionModal';
import PublishDraftModal from '^/components/admin/template-documents/modals/PublishDraftModal';
import ApplyTemplateActivityToLiveCyclesModal from '^/components/admin/activities/apply-to-live-cycles/ApplyTemplateActivityToLiveCyclesModal';
import ReportModalContent from '^/components/admin/reports/ReportsModalContent';
import GroupActivitiesAndTasksReportModal from '^/components/admin/reports/GroupActivitiesAndTasksReportModal';
import ConfirmChangeActivityDateModal from '^/components/app/activities/modals/ConfirmChangeActivityDateModal';
import ReassignPrivateDocumentsModal from '^/components/app/template-documents/modals/ReassignPrivateDocumentsModal';
import HelpModal from '^/components/app/help/HelpModal';
import DeleteCopyModal from '^/components/app/template-documents/modals/DeleteCopyModal';
import DiscardCurrentAndAdoptNewVersionModal from '^/components/app/template-documents/modals/DiscardCurrentAndAdoptNewVersionModal';
import ConnectedDemoModeDisallowsModal from '^/components/app/perms/DemoModeDisallowsModal';
import DocReadAddTeamMembersModal from '^/components/app/template-documents/modals/DocReadAddTeamMembersModal';
import ArchiveDocumentToReadModal from '^/components/app/template-documents/modals/ArchiveDocumentToReadModal';
import DocumentTakeOverModal from '^/components/app/template-documents/modals/DocumentTakeOverModal';
import CreateBulkDocReadModal  from '^/components/app/template-documents/modals/CreateBulkDocReadModal';
import CreateChecklistRecordGroupModal from '^/components/app/digital-tools/records/checklist/modals/CreateChecklistRecordGroupModal';
import RefreshChecklistConfirmationModal from '^/components/app/digital-tools/records/checklist/modals/RefreshChecklistConfirmationModal';
import ArchiveChecklistConfirmationModal from '^/components/app/digital-tools/records/checklist/modals/ArchiveChecklistConfirmationModal';
import CreateCustomFieldModal from '^/components/app/digital-tools/records/checklist/modals/CreateCustomFieldModal';
import ExitCreateRecordWithCustomFieldsConfirmationModal from '^/components/app/digital-tools/records/checklist/modals/ExitCreateRecordWithCustomFieldsConfirmationModal';
import DocumentReviewModal from '^/components/app/template-documents/modals/DocumentReviewModal';
import InheritGroupsFunctionalityModal from '^/components/admin/practices/modals/InheritGroupsFunctionalityModal';

import * as MODAL_IDS from './ids';

const MODAL_MAP = {
  [MODAL_IDS.CREATE_TASK]: CreateTask,
  [MODAL_IDS.CREATE_EDIT_GROUP_TASK_MODAL]: CreateEditGroupTasksModal,
  [MODAL_IDS.ARCHIVE_GROUP_TASK_MODAL]: ArchiveGroupTasksModal,
  [MODAL_IDS.EDIT_PRACTICE_MODAL]: EditPracticeModal,
  [MODAL_IDS.EDIT_GROUP_DETAILS_MODAL]: EditGroupDetailsModal,
  [MODAL_IDS.ADD_NEW_OR_EXISTING_TEAM_MEMBER_TABSET]: AddNewOrExistingTeamMemberTabset,
  [MODAL_IDS.EXPORT_MODAL_CONTENT]: ExportModalContent,
  [MODAL_IDS.EDIT_MEMBER_MODAL_CONTENT]: EditMemberModalContent,
  [MODAL_IDS.EDIT_MEMBER_ACTIVITIES_MODAL]: EditMemberActivitiesModal,
  [MODAL_IDS.SUSPEND_MEMBER_MODAL]: SuspendMemberModal,
  [MODAL_IDS.RETIRE_MEMBER_MODAL]: RetireMemberModal,
  [MODAL_IDS.REINSTATE_MEMBER_MODAL]: ReinstateMemberModal,
  [MODAL_IDS.SETUP_CYCLE_DATE_FORM_MODAL]: SetupCycleDateFormModal,
  [MODAL_IDS.UPLOAD_ACTIVITY_TASK_ASSIGNEE_FILE_MODAL]: UploadActivityTaskAssigneeFileModal,
  [MODAL_IDS.REMOVE_UPLOADED_ACTIVITY_FILE_MODAL]: RemoveUploadedActivityFileModal,
  [MODAL_IDS.TOGGLE_PRIVACY_UPLOADED_ACTIVITY_TASK_FILE_MODAL]: TogglePrivacyUploadedActivityTaskFileModal,
  [MODAL_IDS.CHANGE_UPLOADED_TASK_FILE_FOLDER_MODAL]: ChangeUploadedTaskFileFolderModal,
  [MODAL_IDS.CONFIRM_DEMO_RESET_MODAL]: ConfirmDemoResetModal,
  [MODAL_IDS.CONFIRM_UPGRADE_DEMO_MODAL]: ConfirmUpgradeDemoModal,
  [MODAL_IDS.DISCARD_WORKING_VERSION_MODAL]: DiscardWorkingVersionModal,
  [MODAL_IDS.PUBLISH_AS_CURRENT_VERSION_MODAL]: PublishAsCurrentVersionModal,
  [MODAL_IDS.CONFIRM_CHANGE_ACTIVITY_DATE_MODAL]: ConfirmChangeActivityDateModal,
  [MODAL_IDS.PUBLISH_AS_NEW_VERSION_MODAL]: PublishAsNewVersionModal,
  [MODAL_IDS.PUBLISH_DRAFT_MODAL]: PublishDraftModal,
  [MODAL_IDS.REPORT_MODAL_CONTENT]: ReportModalContent,
  [MODAL_IDS.GROUP_ACTIVITIES_AND_TASKS_REPORT]: GroupActivitiesAndTasksReportModal,
  [MODAL_IDS.MARK_TASK_COMPLETE_MODAL]: MarkTaskCompleteModal,
  [MODAL_IDS.APPLY_TEMPLATE_ACTIVITY_TO_LIVE_CYCLES_MODAL]: ApplyTemplateActivityToLiveCyclesModal,
  [MODAL_IDS.PRACTICE_ADOPTION_MODAL]: PracticeAdoptionModal,
  [MODAL_IDS.HELP_MODAL]: HelpModal,
  [MODAL_IDS.REASSIGN_PRIVATE_DOCUMENTS_MODAL]: ReassignPrivateDocumentsModal,
  [MODAL_IDS.RESTORE_ADOPTED_VERSION_MODAL]: RestoreAdoptedVersionModal,
  [MODAL_IDS.EXIT_CONFIRMATION_MODAL]: ExitConfirmationModal,
  [MODAL_IDS.COMPARISON_EDITOR_EXIT_CONFIRMATION_MODAL]: ComparisonEditorExitConfirmationModal,
  [MODAL_IDS.NAME_DOCUMENT_COPY_MODAL]: NameDocumentCopyModal,
  [MODAL_IDS.TASKS_LEFT_UNDELETED]: TasksLeftUndeleted,
  [MODAL_IDS.DOCUMENT_FOLDER_EXPORT_MODAL]: DocumentFolderExportModal,
  [MODAL_IDS.DELETE_FOLDER_CONFIRMATION_MODAL]: DeleteFolderConfirmationModal,
  [MODAL_IDS.DELETE_COPY_MODAL]: DeleteCopyModal,
  [MODAL_IDS.MAINTENANCE_MODAL]: MaintenanceModal,
  [MODAL_IDS.REASSIGN_CONFIRMATION_MODAL]: ReassignConfirmationModal,
  [MODAL_IDS.CONNECTED_DEMO_MODE_DISALLOWS_MODAL]: ConnectedDemoModeDisallowsModal,
  [MODAL_IDS.CREATE_GROUP_FOLDER_MODAL]: CreateGroupFolderModal,
  [MODAL_IDS.PUBLISH_GROUP_DOCUMENT_MODAL]: PublishGroupDocumentModal,
  [MODAL_IDS.EDIT_GROUP_CREATED_DOCUMENT_MODAL]: GroupTemplateDocumentEditModal,
  [MODAL_IDS.PUBLISH_GROUP_DOCUMENT_NEW_VERSION_MODAL]: GroupTemplateDocumentNewVersionModal,
  [MODAL_IDS.DELETE_HEALTHCHECK_MODAL]: DeleteHealthcheckModal,
  [MODAL_IDS.DELETE_AUDIT_MODAL]: DeleteAuditModal,
  [MODAL_IDS.COMPLETE_HEALTHCHECK_CONFIRM_MODAL]: CompleteHealthcheckConfirmModal,
  [MODAL_IDS.AUDIT_STAGE_CONFIRM_MODAL]: ConfirmAuditStageModal,
  [MODAL_IDS.DISCARD_CURRENT_AND_ADOPT_NEW_VERSION]: DiscardCurrentAndAdoptNewVersionModal,
  [MODAL_IDS.REMOVE_UPLOADED_DOCUMENT]: RemoveUploadedDocumentModal,
  [MODAL_IDS.DOC_READ_ADD_TEAM_MEMBERS_MODAL]: DocReadAddTeamMembersModal,
  [MODAL_IDS.DOCUMENT_TO_READ]: DocumentToReadModal,
  [MODAL_IDS.ARCHIVE_DOCUMENT_TO_READ]: ArchiveDocumentToReadModal,
  [MODAL_IDS.EXIT_RECORD_FORM_CONFIRMATION_MODAL]: ExitRecordFormConfirmationModal,
  [MODAL_IDS.DOCUMENT_TAKE_OVER_MODAL]: DocumentTakeOverModal,
  [MODAL_IDS.CREATE_BULK_DOC_READ_MODAL]: CreateBulkDocReadModal,
  [MODAL_IDS.CREATE_CHECKLIST_RECORD_GROUP_MODAL]: CreateChecklistRecordGroupModal,
  [MODAL_IDS.REFRESH_CHECKLIST_CONFIRMATION_MODAL]: RefreshChecklistConfirmationModal,
  [MODAL_IDS.ARCHIVE_CHECKLIST_CONFIRMATION_MODAL]: ArchiveChecklistConfirmationModal,
  [MODAL_IDS.CREATE_CUSTOM_FIELD_MODAL]: CreateCustomFieldModal,
  [MODAL_IDS.EXIT_CREATE_RECORD_WITH_CUSTOM_FIELDS_CONFIRMATION_MODAL]: ExitCreateRecordWithCustomFieldsConfirmationModal,
  [MODAL_IDS.DOCUMENT_REVIEW_CONFIRMATION_MODAL]: DocumentReviewModal,
  [MODAL_IDS.INHERIT_GROUPS_FUNCTIONALITY_MODAL]: InheritGroupsFunctionalityModal,
};

export default MODAL_MAP;
