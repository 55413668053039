import React from 'react';

import { hasStats } from '^/models/activities';
import LandingCard from '^/components/app/groups/insights/LandingCard';
import ActivitiesChart from '^/components/app/groups/insights/activities/chart/ActivitiesChart';
import { t } from '^/i18n';

export const ActivityStatsProgress = ({ stats }) => {
  return (
    <div className="mt-1">
      <LandingCard
        title="Activities (last 12 months)"
        link={''}
        linkText=""
        lastUpdatedTimestamp={null}
        query={{}}
        className='activities-insights-card full-height text-center'
      >
        {hasStats(stats) ?
          <ActivitiesChart data={stats} />
        :
          <div className="mt-3">{ t('activities.completeFirstActivity', 'Complete your first Activity to see how your progress will show') }</div>
        }
      </LandingCard>
    </div>
  );

};

export default ActivityStatsProgress;
