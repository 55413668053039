import React from 'react';
import { connect } from 'react-redux';
import { fromJS, Map } from 'immutable';
import classNames from 'classnames';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import { DOCUMENT_VERSIONS_TO_READ } from '^/consts/collectionKeys';
import TableList from '^/components/app/tables/TableList';
import { documentVersionToReadSendReminder } from '^/actions/actions';
import { isDocumentReadByAllMembers, ARCHIVED } from '^/utils';
import { openCreateBulkDocReadModal } from '^/actions/modals';
import DocumentReadFilterBar from '^/components/app/template-documents/DocumentReadFilterBar';
import { READ_TRACKER_FILTER_KEYS as FILTER_KEYS } from '^/components/app/template-documents/constants';
import { isPending } from '^/consts/responseStates';
import { 
  getCollection,
  clearCollectionFilter,
  clearCollectionSearchString,
} from '^/actions/collections';
import { selectCollectionFilters } from '^/selectors/collections';


export const PAGE_SIZE = 20;

export class DocumentReadTracker extends PureComponent {

  constructor(props) {
     super(props);
     this.state = {selectedFilters: this.getEmptyFilters()};
     this.changeFilter = this.changeFilter.bind(this);
     this.changeFilterAndApply = this.changeFilterAndApply.bind(this);
     this.clearFiltersAndApply = this.clearFiltersAndApply.bind(this);
     this.loadForFilters = this.loadForFilters.bind(this);
  }

  componentWillUnmount() {
    this.props.clearCollectionFilter(DOCUMENT_VERSIONS_TO_READ);
    this.props.clearCollectionSearchString(DOCUMENT_VERSIONS_TO_READ);
  }

  getEmptyFilters() {
    return Map({
      [FILTER_KEYS.DOCUMENT_NAME]: "",
      [FILTER_KEYS.DOCUMENT_CODE]: "",
      [FILTER_KEYS.SENT_TO]: "",
      [FILTER_KEYS.DATE_FROM]: "",
      [FILTER_KEYS.DATE_TO]: "",
    })
  }

  changeFilter(filterKey, value) {
    this.setState(prevState => {
      return {selectedFilters: prevState.selectedFilters.set(filterKey, value)}
    });
  }

  changeFilterAndApply(filterKey, value) {
    // skip if entered filter value is same as currently applied one
    if(this.props.listAppliedFilters && this.props.listAppliedFilters[filterKey] === value) {
      return;
    }
    this.setState(
      prevState => {
        return {selectedFilters: prevState.selectedFilters.set(filterKey, value)}
      },
      () => this.loadForFilters()
    )
  }

  clearFiltersAndApply() {
    this.setState(
      () => { return {selectedFilters: this.getEmptyFilters()}; },
      () => this.loadForFilters()
    );
  }

  loadForFilters() {
    const { 
      isListLoading, listSortBy, listSortByReversed, listPageSize,
    } = this.props;
    const ordering = listSortBy && listSortByReversed ? '-' + listSortBy : listSortBy;
    if (!isListLoading) {
      this.props.getCollection(
        DOCUMENT_VERSIONS_TO_READ,
        {
          page: 1,
          pageSize: listPageSize, 
          shouldAppend: false,
          additionalParams: {ordering},
          filters: this.state.selectedFilters.toJS(),
        },
      );
    }
  }

  isDisabled(documentReadRequest) {
    return (
      documentReadRequest.get('status') === ARCHIVED ||
      isDocumentReadByAllMembers(documentReadRequest)
    );
  }

  getReadStatusStyle(documentReadRequest) {
    return classNames(
      'doc-read-status',
      isDocumentReadByAllMembers(documentReadRequest)
        ? 'complete'
        : 'incomplete'
    );
  }

  render() {
    const { practiceId } = this.props;
    const fields = fromJS([
      {
        name: ['document_name'],
        display_name: t('templateDocuments.documentName', 'Document Name'),
        type: 'string',
        sortable: true,
      },
      {
        name: ['document_code'],
        display_name: t('templateDocuments.documentCode', 'Document Code'),
        type: 'string',
        sortable: true,
      },
      {
        name: ['read_status'],
        display_name: t('templateDocuments.readStatus', 'Read Status'),
        type: 'string',
        sortByName: 'outstanding_read',
        sortable: true,
        styleHandler: this.getReadStatusStyle,
      },
      {
        name: ['status'],
        display_name: t('templateDocuments.status', 'Status'),
        type: 'string',
        sortable: true,
      },
      {
        display_name: t('templateDocuments.sendReminder', 'Send Reminder'),
        type: 'live_button',
        liveButton: {
          text: t('templateDocuments.send', 'Send'),
          successText: t('templateDocuments.sent', 'Sent!'),
          keyedResponse: this.props.sendReminderResponse,
          disabled: this.isDisabled,
        },
        handler: this.props.sendReminder,
      },
      {
        display_name: t('templateDocuments.action', 'Action'),
        type: 'detail',
        link_text: t('templateDocuments.view', 'View'),
        route: '/page/documents/document-read-tracker/${id}/',
      },
    ]);

    return (
      <div className="wrapper mb-2 mt-1">
        <div className="pull-right">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => this.props.openCreateBulkDocReadModal(practiceId)}
          >
            {t('templateDocuments.sendMultipleDocuments', 'Send Multiple Documents')}
          </button>
        </div>
        <h2 className="mb-2">Document Read Tracker</h2>
        <DocumentReadFilterBar
          filters={this.state.selectedFilters.toJS()}
          onFilterChanging={this.changeFilter}          
          onFilterChanged={this.changeFilterAndApply}
          onClearClicked={this.clearFiltersAndApply}
          isDisabled={this.props.isListLoading}
        />
        <TableList
          model={DOCUMENT_VERSIONS_TO_READ}
          fields={fields}
          title={t('templateDocuments.documentReadTracker', 'Document Read Tracker')}
          pageSize={PAGE_SIZE}
          noSearch
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sendReminderResponse: state.responses.get('documentVersionToReadSendReminderResponse'),
    practiceId: (state.currentPractice && state.currentPractice.get('id')),
    isListLoading: isPending(state.responses.getIn(['getCollection', DOCUMENT_VERSIONS_TO_READ])),
    listSortBy: state.ui.getIn(['sortBy', DOCUMENT_VERSIONS_TO_READ]),
    listSortByReversed: state.ui.getIn(['sortByReversed', DOCUMENT_VERSIONS_TO_READ]),
    listPageSize: state.ui.getIn(['tablePageSize', DOCUMENT_VERSIONS_TO_READ]) || PAGE_SIZE,
    listAppliedFilters: selectCollectionFilters(state, DOCUMENT_VERSIONS_TO_READ),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    openCreateBulkDocReadModal: practiceId => dispatch(openCreateBulkDocReadModal(practiceId)),
    sendReminder: documentVersionToRead => dispatch(documentVersionToReadSendReminder(documentVersionToRead.get('id'))),
    getCollection: (type, opts, name=type) => dispatch(getCollection(type, opts, name)),
    clearCollectionFilter: name => dispatch(clearCollectionFilter(name)),
    clearCollectionSearchString: name => dispatch(clearCollectionSearchString(name)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentReadTracker);
