import React from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router';
import { isQPSMember } from '^/models/practice';
import { PRACTICES } from '^/consts/collectionKeys';
import { openConfirmDemoResetModal, openConfirmUpgradeDemoModal } from '^/actions/modals';
import { isDemoUser } from '^/models/user';
import { makeStaticPath } from '^/utils';

import { t } from '^/i18n';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import ActivityStats from '^/components/app/stats/activities/ActivityStats';
import PracticeMembersFilter from '^/components/app/dashboard/PracticeMembersFilter';
import TotalHRPanel from '^/components/app/dashboard/TotalHRPanel';
import PracticeSwitcher from '^/components/app/dashboard/PracticeSwitcher';
import DashboardActivitiesAndUpdatedTemplate from '^/components/app/dashboard/DashboardActivitiesAndUpdatedTemplate';
import CheckListSwitcher from '^/components/app/dashboard/QuickcheckChecklist';
import Tasks from '^/components/app/dashboard/Tasks';
import LatestNews from '^/components/app/dashboard/LatestNews';
import SubsequentCycleSetupAlert from '^/components/app/widgets/SubsequentCycleSetupAlert';
import DemoSplash from '^/components/app/dashboard/DemoSplash';
import ShowIfAdmin from '^/components/app/perms/ShowIfAdmin';
import HealthcheckBanner from '^/components/app/healthcheck/banner';
import { practiceHasAccessToDigitalRecordsQuickCheck } from '^/stateHelpers';

export class Dashboard extends PureComponent {
  render() {
    const { currentPractice, practices, user, showQuickCheck } = this.props;

    const practiceId = currentPractice && currentPractice.get('id');
    const practiceName = currentPractice && currentPractice.get('name');

    return (
      <div>

        {isDemoUser(user) &&
          <DemoSplash
            onClickSignup={this.props.openConfirmUpgradeDemoModal}
            onClickReset={this.props.openConfirmDemoResetModal}
          />
        }

        <div className="col-3-4 bordered">

          {currentPractice && (
            isQPSMember(currentPractice) ? (
              <Link to="/page/qps-scheme/">
                <img src={makeStaticPath('images/qps-scheme.png')} width="67" height="68" className="pull-right mt-2" />
              </Link>
            ) : (
              <div className="pull-right qps-advert">
                {t('dashboard.giveYourPracticeThe', 'Give your practice the')}<br />
                {t('dashboard.recognitionItDeserves', 'recognition it deserves')} &mdash;<br />
                <a href="https://codeuk.com/qps/" target="_blank" className="text-underlined">
                  {t('dashboard.signUpToThe', 'sign up to the')}<br />
                  {t('dashboard.qualityPracticeSchemeToday', 'Quality Practice Scheme today!')}
                </a>
              </div>
            )
          )}
          <h1 className=" mt-2">
            <span className="light-text">{practiceName}</span>
          </h1>
          {practices && !practices.isEmpty() && (
            <PracticeSwitcher practices={practices} practiceId={practiceId} />
          )}
          <PracticeMembersFilter />
          <hr className="thin" />
          <HealthcheckBanner />
          <ShowIfAdmin>
            <SubsequentCycleSetupAlert />
          </ShowIfAdmin>
          {practiceId ? (
            <div>
              <ActivityStats practiceId={practiceId} />
              <DashboardActivitiesAndUpdatedTemplate practiceId={practiceId} />
            </div>
          ) : (
            <Loading />
          )}
        </div>

        <div className="col-1-4 col-md-1 col-last">
          {showQuickCheck && (
            <div className="sidebar-item">
              <CheckListSwitcher />
            </div>
          )}
          <div className="sidebar-item">
            <Tasks />
          </div>
          <hr className="thin hidden-m m0" />
          <div className="pt-1 pb-1">
            <TotalHRPanel />
          </div>
          <hr className="thin hidden-m m0" />
          <div className="sidebar-item">
            <LatestNews practiceId={practiceId} />
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  currentPractice: ImmutablePropTypes.map,
  practices: ImmutablePropTypes.list.isRequired,
};

export function mapStateToProps(state) {
  return {
    user: state.userProfile || Map(),
    practices: state.collections.getIn([PRACTICES, 'items'], List()),
    currentPractice: state.currentPractice,
    showQuickCheck: practiceHasAccessToDigitalRecordsQuickCheck(state),
  };
}

export default connect(
  mapStateToProps,
  {
    openConfirmUpgradeDemoModal,
    openConfirmDemoResetModal,
  }
)(Dashboard);
