import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { t } from '^/i18n';

const CycleProgressToDateText = ({ progress }) =>
  <div className="x-small-text">
    { t('widgets.cycleProgressToDate.inThisCycleYouHave', 'In this cycle, you have') } <span className="text-outstanding small-text">
    {progress.get('overdue')} { t('widgets.cycleProgressToDate.outstandingActivities', 'outstanding activities') } </span>
    { t('widgets.cycleProgressToDate.fromAPossible', 'from a possible') } <span className="text-info small-text">{progress.get('total_to_date')} { t('widgets.cycleProgressToDate.toDate', 'to date') }</span>.
  </div>;

CycleProgressToDateText.propTypes = {
  progress: ImmutablePropTypes.map.isRequired,
};

export default CycleProgressToDateText;
