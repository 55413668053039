import { t } from '^/i18n';

export const TABS = [
  {
    path: 'dashboard',
    label: t('common.dashboard', 'dashboard'),
    counter: 'overDueActivityCount',
  },
  {
    path: 'calendar',
    label: t('common.calendar', 'calendar'),
    counter: 'alertsCount',
  },
  {
    path: 'tasks',
    label: t('common.tasks', 'tasks'),
    applicationOnly: true,
  },
  {
    path: 'documents',
    label: t('common.documents', 'documents'),
  },
  {
    path: 'reports',
    label: t('common.reports', 'reports'),
    applicationOnly: true,
    adminOnly: true,
  },
  {
    path: 'tools',
    label: t('common.digitalTools', 'digital tools'),
  },
  {
    path: 'practices',
    label: t('common.practice', 'practice'),
  },
  {
    path: 'help',
    label: t('common.help', 'help'),
    iconType: 'comments',
    iconClassName: 'white-icon title ml-1',
  },
];

export const getGroupsTabConfig = (
  hasGroupDocumentsAccess,
  hasAccessToGroupConfiguration,
  hasGroupNewsTriageAccess,
  hasGroupPracticeInteractionReportAccess
) => {
  const GROUPS_TABS = [
    {
      path: 'groups/insights',
      label: t('common.insights', 'insights'),
    },
    {
      path: 'groups/group-details',
      label: t('common.details', 'details'),
    },
    {
      path: 'groups/group-tasks',
      label: t('common.tasks', 'tasks'),
    },
  ];

  if (hasGroupDocumentsAccess) {
    GROUPS_TABS.splice(2, 0, {
      path: 'groups/group-documents',
      label: t('common.documents', 'documents'),
      counter: 'groupDocumentsAlertsCount',
    });
  }

  if (hasAccessToGroupConfiguration) {
    GROUPS_TABS.push({
      path: 'groups/group-config',
      label: t('common.groupConfig', 'group config'),
    });
  }

  if (hasGroupNewsTriageAccess) {
    GROUPS_TABS.push({
      path: 'groups/news',
      label: t('common.news', 'news'),
    });
  }

  GROUPS_TABS.push({
    path: 'groups/reports',
    label: t('common.reports', 'news'),
  });

  if (hasGroupPracticeInteractionReportAccess) {
    GROUPS_TABS.push({
      path: 'groups/interaction',
      label: t('common.interaction', 'interaction'),
    });
  }

  return GROUPS_TABS;
};
