import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import PureComponent from '^/components/common/PureComponent';
import { closeModal } from '^/actions/modals';
import Icon from '^/components/app/content/Icon';
import classNames from 'classnames';
import MODAL_MAP from '^/modals/map';

const ESCAPE_KEY_CODE = 27;

export class Modal extends PureComponent {
  constructor() {
    super();

    this.handleKeyDown = function (event) {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        this.closeModal();
      }
    }.bind(this);
  }

  getClassName(className) {
    const baseClassName = 'modal-wrapper';

    if (className) {
      return [baseClassName, className].join(' ');
    }
    return baseClassName;
  }

  closeModal() {
    if (this.props.modalConfig.get('closable', true)) {
      this.props.closeModal();
    }
  }

  componentWillMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    const modalConfig = this.props.modalConfig;
    const closable = modalConfig.get('closable', true);
    const emptyModal = modalConfig.get('emptyModal', false);

    const { className, title, id, props, open, wide, extraWide } = modalConfig.toJS();

    const Body = MODAL_MAP[id];

    let icon;
    if (closable) {
      icon = (
        <span
          className={classNames('pull-right modal-close', emptyModal && 'modal-close-w-margin')}
          onClick={this.closeModal.bind(this)}
        >
          <Icon type="close" />
        </span>
      );
    }

    return (
      <ReactCSSTransitionGroup
        transitionName="modal-transition"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {
          open && (
            <div className={this.getClassName(className)}>
              <div className="modal-overlay" onClick={this.closeModal.bind(this)}>
                <div
                  className={classNames(
                    'modal row content-box',
                    wide && 'modal-wide',
                    extraWide && 'modal-extra-wide'
                  )}
                  onClick={event => event.stopPropagation()}
                >
                  {!emptyModal ?
                    <div>
                      <div className="modal-title">
                        <h3>
                          {title}
                        </h3>
                        {icon}
                      </div>
                      <div className="modal-body">
                        <Body {...props} />
                      </div>
                    </div>
                  :
                    <div>
                      {icon}
                      <Body {...props} />
                    </div>
                  }
                </div>
              </div>
            </div>
          )
        }
      </ReactCSSTransitionGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    modalConfig: state.ui.get('modalConfig')
  };
}

export default connect(
  mapStateToProps,
  {
    closeModal
  }
)(Modal);
