import { Map } from 'immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { browserHistory } from 'react-router';
import { syncHistory } from 'react-router-redux';
import { apiMiddleware } from 'redux-api-middleware';

import rootReducer from '^/reducers/rootReducer';
import actionChain from '^/middleware/actionChain';
import pollExport from '^/middleware/pollExport';
import pollGroupTaskUpdate from '^/middleware/pollGroupTaskUpdate';
import pollBulkDocSendReadRequest from '^/middleware/pollBulkDocSendReadRequest';
import navigation from '^/middleware/navigation';
import authentication from '^/middleware/authentication';
import maintenance from '^/middleware/maintenance';

const reduxRouterMiddleware = syncHistory(browserHistory);

const finalCreateStore = compose(
  applyMiddleware(
    thunk,
    apiMiddleware,
    authentication,
    navigation,
    pollExport,
    pollGroupTaskUpdate,
    pollBulkDocSendReadRequest,
    maintenance,
    reduxRouterMiddleware,
    actionChain //This item MUST be last in the queue
  ),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
)(createStore);

const defaultUser = window.USER_ID ? Map({ loggedInUserId: window.USER_ID }) : Map();
const defaultStoreValues = { user: defaultUser };

export { finalCreateStore as createStore }

const store = finalCreateStore(rootReducer, defaultStoreValues);

export default store;
