import React from 'react';
import { reduxForm } from 'redux-form';
import { Set } from 'immutable';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';

import { hasSucceeded } from '^/consts/responseStates';
import { uploadFile } from '^/actions/actionSequences';
import { setCollapse } from '^/actions/actions';
import { EVENT_TYPE_OPTIONS } from '^/components/app/digital-tools/records/digital-records/constants';
import {
  EVENT_SEVERITY_OPTIONS, 
  EVENT_STATUS_CHOICES,
  getPracticeMembersOptions,
  renderOptionsField,
  renderTextField,
  renderPracticeMembersField,
  renderDateField,
  renderCheckboxField,
  renderNotesField,
  renderTextAreaField,
  renderRelatedTasksField,
  renderChoosePracticeMembersField,
  renderMultipleFileUploadField,
} from '^/components/app/digital-tools/records/digital-records/forms/FormFields';
import { t } from '^/i18n';

export const EVENT_FORM = 'EVENT_FORM';
export const GENERAL_COLLPASE_ID = 'GENERAL_COLLPASE_ID';
export const ANALYSIS_COLLPASE_ID = 'ANALYSIS_COLLPASE_ID';
export const ACTIONS_COLLPASE_ID = 'ACTIONS_COLLPASE_ID';

export class EventForm extends PureComponent {
  componentWillMount() {
    this.props.setCollapse(GENERAL_COLLPASE_ID, true);
    this.props.setCollapse(ANALYSIS_COLLPASE_ID, true);
    this.props.setCollapse(ACTIONS_COLLPASE_ID, true);
  }

  render() {
    const {
      handleSubmit,
      fields,
      response,
      values,
      practiceMembers,
      isEditing,
      isArchived,
      staff_tasks,
      handleArchive,
      archiveDigitalRecordResponse,
      updateDigitalRecordResponse,
      createDigitalRecordResponse,
      uploadFileResponse,
    } = this.props;

    const {
      reference,
      type,
      assign_to,
      severity,
      event_date,
      people_involved,
      team_members_involved,
      visibility_restricted,
      collaborators,
      notes,
      what_happened_notes,
      why_it_happened_notes,
      what_went_well_notes,
      what_went_bad_notes,
      learning_points_notes,
      status,
      initial_actions_notes,
      prevent_reoccurrence_notes,
      related_files,
    } = fields;

    const teamMembersInvolvedSet =
      values && values.team_members_involved ? Set(values.team_members_involved) : Set();

    const collaboratorsSet =
      values && values.collaborators ? Set(values.collaborators) : Set();

    const allPracticeMembersOptions = getPracticeMembersOptions(practiceMembers);

    return (
      <form onSubmit={handleSubmit}>
        <div className="collapsible-section-records mt-2">
          <CollapseButton collapseId={GENERAL_COLLPASE_ID}>
            <label className="record-section-heading">{ t('common.label.general', 'General') }</label>
          </CollapseButton>
          <Collapse initiallyCollapsed collapseId={GENERAL_COLLPASE_ID}>
            <div className="mt-2">
              {renderTextField(reference, t('digitalTools.forms.referenceLabel', 'Reference*:'), response, isArchived)}
              {renderOptionsField(
                type,
                `${t('digitalTools.forms.label.typeofEvent', 'Type of Event')}:`,
                EVENT_TYPE_OPTIONS,
                t('digitalTools.forms.label.selectTypeOfEvent', 'Select type of Event'),
                response,
                isArchived,
                true
              )}
              {renderPracticeMembersField(
                assign_to,
                `${t('digitalTools.forms.label.leadingTeamMember', 'Leading team member dealing with Event')}:`,
                allPracticeMembersOptions,
                response,
                isArchived,
                true
              )}
              {renderOptionsField(
                severity,
                `${t('digitalTools.forms.label.severity', 'Severity')}:`,
                EVENT_SEVERITY_OPTIONS,
                t('digitalTools.forms.label.selectSeverity', 'Select severity'),
                response,
                isArchived,
                true
              )}
              {renderDateField(
                event_date,
                t('digitalTools.forms.label.dateOfEvent', 'Date of Event'),
                response,
                isArchived,
                true
              )}
              {renderTextField(people_involved, t('digitalTools.forms.label.peopleInvolved', 'People Involved:'), response, isArchived)}
              {renderChoosePracticeMembersField(
                team_members_involved,
                `${ t('digitalTools.forms.label.teamMembersInvolved.capitalCase', 'Team members involved')}:`,
                t('digitalTools.forms.label.teamMembersInvolved', 'team member involved'),
                teamMembersInvolvedSet,
                allPracticeMembersOptions,
                isArchived
              )}
              {renderCheckboxField(visibility_restricted, `${t('digitalTools.forms.label.restrictVisibility', 'Restrict Visibility')}:`, response, isArchived)}
              {renderChoosePracticeMembersField(
                collaborators,
                `${t('digitalTools.forms.label.addCollaborator', 'Add Collaborator')}:`,
                t('digitalTools.forms.label.collaborators', 'collaborator'),
                collaboratorsSet,
                allPracticeMembersOptions,
                isArchived
              )}
              {renderMultipleFileUploadField(
                related_files,
                `${t('digitalTools.forms.label.supportingEvidence', 'Supporting Evidence')}:`,
                handleSubmit,
                this.props.uploadFile,
                uploadFileResponse,
                EVENT_FORM,
                isArchived
              )}
              {renderNotesField(notes, response, isArchived)}
            </div>
          </Collapse>
        </div>
        <div className="collapsible-section-records mt-2">
          <CollapseButton collapseId={ANALYSIS_COLLPASE_ID}>
            <label className="record-section-heading">{ t('digitalTools.forms.label.analysis', 'Analysis') }</label>
          </CollapseButton>
          <Collapse initiallyCollapsed collapseId={ANALYSIS_COLLPASE_ID}>
            <div className="mt-2">
              {renderTextAreaField(what_happened_notes, t('digitalTools.records.whatHappened', 'What happened?'), response, isArchived)}
              {renderTextAreaField(why_it_happened_notes, t('digitalTools.records.whyDidItHappen', 'Why did it happen?'), response, isArchived)}
              {renderTextAreaField(what_went_well_notes, t('digitalTools.records.whatWentWell', 'What went well?'), response, isArchived)}
              {renderTextAreaField(what_went_bad_notes, t('digitalTools.records.whatCouldHaveBeenHandledBetter', 'What could have been handled better?'), response, isArchived)}
              {renderTextAreaField(learning_points_notes, t('digitalTools.records.whatWereTheLearningPoints', 'What were the learning points?'), response, isArchived)}
            </div>
          </Collapse>
        </div>
        <div className="collapsible-section-records mt-2 mb-2">
          <CollapseButton collapseId={ACTIONS_COLLPASE_ID}>
            <label className="record-section-heading">{ t('digitalTools.labels.actionsAndImplementation', 'Actions and Implementation') }</label>
          </CollapseButton>
          <Collapse initiallyCollapsed collapseId={ACTIONS_COLLPASE_ID}>
            <div className="mt-2">
              {renderOptionsField(
                status,
                `${t('common.status', 'Status')}:`,
                EVENT_STATUS_CHOICES,
                `${t('common.label.pleaseChooseStatus', 'Please choose a status')}...`,
                response,
                isArchived,
                false
              )}
              {renderTextAreaField(initial_actions_notes, `${'digitalTools.forms.initialActions', 'Initial Actions'}:`, response, isArchived)}
              {renderTextAreaField(
                prevent_reoccurrence_notes,
                t('digitalTools.records.whatWillBeDoneToStopThisEventFromHappeningAgain', 'What has been/will be done to stop this Event from happening again?'),
                response,
                isArchived
              )}
              {renderRelatedTasksField(staff_tasks, isArchived)}
            </div>
          </Collapse>
        </div>
        {!isEditing && !isArchived &&
          <div className="form-group">
            <div className="col-1-3">
              <LiveButton
                pendingMessage={`${t('common.creating', 'Creating')}...`}
                response={createDigitalRecordResponse}
                className="btn-default pd-2"
              >
              { t('common.button.save', 'Save') }
              </LiveButton>
            </div>
          </div>
        }
        {!isArchived && isEditing &&
          <div className="form-group">
            <div className="col-2-3">
              <LiveButton
                pendingMessage={`${t('common.label.Archiving', 'Archiving')}...`}
                response={archiveDigitalRecordResponse}
                className="btn-warning pd-2"
                onClick={handleArchive}
              >
                { t('common.button.archive', 'Archive') }
              </LiveButton>
            </div>
            <div className="col-1-3">
              <LiveButton
                pendingMessage={"Saving..."}
                response={updateDigitalRecordResponse}
                className="btn-default pd-2"
              >
              { t('common.button.save', 'Save') }
              </LiveButton>
            </div>
          </div>
        }
        {hasSucceeded(updateDigitalRecordResponse) && (
          <div className="form-group mb-2">
            <p className="alert mt-1 alert-success">{ t('common.label.successfullyUpdated', 'Successfully updated!') }</p>
          </div>
          )
        }
        {hasSucceeded(archiveDigitalRecordResponse) && (
          <div className="form-group mb-2">
            <p className="alert mt-1 alert-warning">{ t('common.label.successfullyArchived', 'Successfully archived!') }</p>
          </div>
          )
        }
      </form>
    );
  }
};

EventForm.propTypes = {
  fields: React.PropTypes.object,
  handleSubmit: React.PropTypes.func.isRequired,
  isEditing: React.PropTypes.bool,
  isArchived: React.PropTypes.bool,
  practiceMembers: ImmutablePropTypes.list,
  response: ImmutablePropTypes.map,
  archiveDigitalRecordResponse: ImmutablePropTypes.map,
  updateDigitalRecordResponse: ImmutablePropTypes.map,
};

export function validate(values) {
  const {
    reference,
    type,
    severity,
    event_date,
  } = values;

  const errors = {};

  if (!reference) {
    errors.reference = t('digitalTools.errors.pleaseProvideAReference', 'Please provide a reference.');
  }

  if (!type) {
    errors.type = t('digitalTools.errors.pleaseProvideAType', 'Please provide a type.')
  }

  if (!severity) {
    errors.severity = t('digitalTools.errors.pleaseProvideASeverity', 'Please provide a severity level.');
  }

  if (!event_date) {
    errors.event_date = t('digitalTools.errors.pleaseProvideADateOfEvent', 'Please provide a date of event.');
  }

  return errors;
}

export function mapStateToProps(state) {
  return {
    uploadFileResponse: state.responses.get('uploadFile'),
  };
}

export const FormifiedEventForm = reduxForm({
  form: EVENT_FORM,
  fields: [
    "reference",
    "type",
    "assign_to",
    "severity",
    "event_date",
    "people_involved",
    "team_members_involved",
    "visibility_restricted",
    "collaborators",
    "notes",
    "what_happened_notes",
    "why_it_happened_notes",
    "what_went_well_notes",
    "what_went_bad_notes",
    "learning_points_notes",
    "status",
    "initial_actions_notes",
    "prevent_reoccurrence_notes",
    "related_files",
  ],
  validate,
})(EventForm);

export default connect(
  mapStateToProps,
  { uploadFile, setCollapse }
)(FormifiedEventForm);
