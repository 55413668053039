import React from 'react';

export const TextFilter = ({
  id, filterKey, label, isDisabled, value, placeholder, onChange,
  onChangeComplete
}) => {
  return (
    <div>
      <label className="display-block mb-1-4 light-text" htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        type="text"
        placeholder={placeholder}
        value={value}
        disabled={isDisabled}
        onChange={event => onChange(filterKey, event.target.value)}
        onBlur={event => onChangeComplete(filterKey, event.target.value)}
        onKeyUp={event => {
          if(event.key === "Enter") {
            onChangeComplete(filterKey, event.target.value);
          }
        }}
      />
    </div>
  );
};

TextFilter.propTypes = {
  id: React.PropTypes.string,
  filterKey: React.PropTypes.string,
  label: React.PropTypes.string,
  isDisabled: React.PropTypes.bool,
  value: React.PropTypes.string,
  placeholder: React.PropTypes.string,
  onChange: React.PropTypes.func.isRequired,
  onChangeComplete: React.PropTypes.func,
};

export default TextFilter;