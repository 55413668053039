import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { getCollection } from '^/actions/collections';
import {
  groupPublishNewsItem,
  groupUnpublishNewsItem,
} from '^/actions/actions';
import { NEWS } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import NewsStoryPreviews from '^/components/app/news/NewsStoryPreviews';
import NewsDetail from '^/components/app/news/NewsDetail';
import { hasAccessToGroupNewsTriage } from '^/stateHelpers';
import { t } from '^/i18n';

export class NewsStoriesPage extends PureComponent {
  constructor(props) {
    super(props);
    this.toggleGroupPublished = this.toggleGroupPublished.bind(this);
  }

  componentWillMount() {
    const { currentPracticeId } = this.props;
    this.loadNewsStories(currentPracticeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPracticeId !== this.props.currentPracticeId) {
      this.loadNewsStories(nextProps.currentPracticeId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    } else if (this.props.newsStoriesCollection.get('page') > 1) {
      const { newsList } = this.refs;
      if (newsList !== undefined) {
        newsList.scrollIntoView({ block: 'end', inline: 'nearest' });
      }
    }
  }

  loadNewsStories(currentPracticeId, opts) {
    if (currentPracticeId) {
      this.props.getCollection(NEWS, {
        ...opts,
        filters: { practice: currentPracticeId },
      });
    }
  }

  loadMoreNewsStories() {
    const { newsStoriesCollection, currentPracticeId } = this.props;
    this.loadNewsStories(currentPracticeId, {
      page: newsStoriesCollection.get('page') + 1,
      shouldAppend: true,
    });
  }

  toggleGroupPublished() {
    const { newsStories, selectedNewsStoryIndex } = this.props;
    const newsStory = newsStories && newsStories.get(selectedNewsStoryIndex);

    if (!newsStory) {
      return;
    }

    if (newsStory.get('is_group_published')) {
      this.props.groupUnpublishNewsItem(newsStory.get('id'));
    } else {
      this.props.groupPublishNewsItem(newsStory.get('id'));
    }
  }

  render() {
    const {
      newsStoriesCollection,
      newsStories,
      selectedNewsStoryIndex,
      response,
      isGroupNewsTriagePage,
      groupPublishResponse,
      groupUnpublishResponse,
      practiceGroupHasNewsTriage,
    } = this.props;

    const newsStory = newsStories && newsStories.get(selectedNewsStoryIndex);

    const showPagination =
      newsStories &&
      !newsStories.isEmpty() &&
      newsStoriesCollection &&
      newsStoriesCollection.get('hasMore');

    if (isPending(response)) {
      return <Loading />;
    }

    return (
      <div className="mt-1">
        <div className="col-3-4 col-md-none">
          {newsStory && (
            <NewsDetail
              newsStory={newsStory}
              newerNewsStory={
                selectedNewsStoryIndex - 1 >= 0
                  ? newsStories.get(selectedNewsStoryIndex - 1, null)
                  : null
              }
              olderNewsStory={newsStories.get(selectedNewsStoryIndex + 1, null)}
              isGroupNewsTriagePage={isGroupNewsTriagePage}
              toggleGroupPublished={this.toggleGroupPublished}
              groupPublishResponse={groupPublishResponse}
              groupUnpublishResponse={groupUnpublishResponse}
              practiceGroupHasNewsTriage={practiceGroupHasNewsTriage}
            />
          )}
        </div>

        <div className="col-1-4 col-md-1 col-last" ref="newsList">
          <div>
            <p className="m-toggle breadcrumb">NEWS ARCHIVES</p>
            {newsStories && newsStories.size ? (
              <div>
                <NewsStoryPreviews
                  newsStories={newsStories}
                  selectedNewsStory={newsStory}
                  isGroupNewsTriagePage={isGroupNewsTriagePage}
                />
              </div>
            ) : (
              <h5 className="text-empty-state">No news stories.</h5>
            )}
          </div>
        </div>
        {showPagination && (
          <a
            className="inline-link pull-right mr-100px mb-1"
            onClick={() => this.loadMoreNewsStories()}
          >
            {t('common.button.loadMore', 'Load more')} {'>>'}
          </a>
        )}
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const newsStoriesCollection = state.collections.get(NEWS);
  const selectedId = props.params.uuid;
  const newsStories =
    newsStoriesCollection && newsStoriesCollection.get('items', List());
  const selectedNewsStoryIndex =
    selectedId && newsStories
      ? newsStories.findIndex(item => item.get('id') === selectedId)
      : 0;

  return {
    newsStoriesCollection,
    newsStories,
    selectedNewsStoryIndex,
    response: state.responses.getIn(['getCollection', NEWS]),
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    groupPublishResponse: state.responses.get('groupPublishNewsItem'),
    groupUnpublishResponse: state.responses.get('groupUnpublishNewsItem'),
    practiceGroupHasNewsTriage: hasAccessToGroupNewsTriage(state),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  groupPublishNewsItem,
  groupUnpublishNewsItem,
})(NewsStoriesPage);
